import React, { useState, useEffect } from "react";
import ModulesMain from "../ModulesMain";
import RadioOptions from "../../../components/radiogroup/RadioOptions";
import Feedback from "../feedback/Feedback";
import PrePostTest from "../pre-post-test/PrePostTest";
import { areAllFieldsFilled } from "../../../utils/Tools";
import { useNavigate } from "react-router-dom";
import { apiPostRequest } from "../../../utils/Network";
import toastr from "toastr";
import calculateUsedTime from "../../../utils/Tools";

export default function Module7() {
    const navigate = useNavigate();
    toastr.options = {
        closeButton: true,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
        timeOut: 3000,
    };


    const [feedback, setFeedback] = useState(
        {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: ""
        }
    )
    const [preTestResults, setPreTestResults] = useState(
        {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: ""
        }
    )
    const [postTestResults, setPostTestResults] = useState(
        {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
        }
    )
    const [isPreTestCompleted, setIsPreTestCompleted] = useState(false)
    const [isActivityCompleted, setActivityCompleted] = useState(false)
    const [moduleActivityData, setModuleActivityData] = useState([]);
    const [pageContent, setPageContent] = useState([
        {
            title: "An individual is able to express oneself, both verbally and non-verbally",
            options: [
                {
                    value: "Communication skill",
                    label: "Communication skill"
                },
                {
                    value: "Managing emotions",
                    label: "Managing emotions"
                },
                {
                    value: "Creative thinking",
                    label: "Creative thinking"
                }
            ],
            selectedValue: "",
        },
        {
            title: "________ skill helps you relate in positive ways with people we interact",
            options: [
                {
                    value: "Decision making",
                    label: "Decision making"
                },
                {
                    value: "Empathy",
                    label: "Empathy"
                },
                {
                    value: "Interpersonal skills",
                    label: "Interpersonal skills"
                }
            ],
            selectedValue: "",
        },
        {
            title: "Developing _________ Skill can help us recognize when we are stressed or under pressure.",
            options: [
                {
                    value: "communication ",
                    label: "communication "
                },
                {
                    value: "Self awareness",
                    label: "Self awareness"
                },
                {
                    value: "stress management",
                    label: "stress management"
                }
            ],
            selectedValue: "",
        },
        {
            title: "___________ skills helps in recognizing emotions within us and others",
            options: [
                {
                    value: "Critical thinking",
                    label: "Critical thinking"
                },
                {
                    value: "Managing emotions",
                    label: "Managing emotions"
                },
                {
                    value: "Decision making",
                    label: "Decision making"
                }
            ],
            selectedValue: "",
        },
        {
            title: "_______________ skill helps in recognizing the sources of stress in our lives,",
            options: [
                {
                    value: "Coping with stress ",
                    label: "Coping with stress "
                },
                {
                    value: "Critical thinking",
                    label: "Critical thinking"
                },
                {
                    value: "Decision making",
                    label: "Decision making"
                }
            ],
            selectedValue: "",
        }, {}
    ]
    )

    const [module7, setModule7] = useState([]);


    const apiCall = async () => {
        try {

            const api = `${process.env.REACT_APP_BASE_URL}/eachmodulestatus`;
            const req_json = { "modulename": "module7", "modulenumber": 7 }
            const response = await apiPostRequest(api, req_json);
            if (response.data.success == true) {
                const dataToPass = response.data.message["modulestatus"];
                if (dataToPass.pretest === 'completed') {
                    setIsPreTestCompleted(true);
                }
                if (dataToPass.pretest === 'completed' && dataToPass.activity === 'completed' && dataToPass.postTest === 'in-progress') {
                    setActivityCompleted(true);
                }
                if (dataToPass.pretest === 'completed' && dataToPass.activity === 'completed') {
                    setIsPreTestCompleted(true);
                    setActivityCompleted(true);

                }
                if (dataToPass.pretest === "completed" && dataToPass.activity === "completed" && dataToPass.posttest === "completed") {
                    navigate("/user-home/activity");
                }
            } else {
                toastr.error(response);
            }
        } catch {
            toastr.error("There is an internal error! Please logout and login.")
        }

    }

    useEffect(() => {
        const modulestatus = JSON.parse(localStorage.getItem("all_module_status"))
        if (modulestatus[5].status === 'completed') {
            apiCall();
        } else {
            navigate('/user-home/activity')
        }
    }, []);



    const handleFbRadioChange = (event, questionNumber) => {
        setFeedback((prevState) => ({
            ...prevState,
            [questionNumber]: event.target.value
        }));
    };
    const handlePreTestResults = (event, questionNumber) => {
        setPreTestResults((prevState) => ({
            ...prevState,
            [questionNumber]: event.target.value
        }));
    }


    const onAgreeDisagreePage4 = (event, itemIndex) => {
        const updatedContent = [...pageContent];

        if (itemIndex >= 0 && itemIndex < updatedContent.length) {
            updatedContent[itemIndex - 1].selectedValue = event.target.value;
            setPageContent(updatedContent);

            const radioValues = {
                ...moduleActivityData,
                [pageContent[0].title]: pageContent[0].selectedValue,
                [pageContent[1].title]: pageContent[1].selectedValue,
                [pageContent[2].title]: pageContent[2].selectedValue,
                [pageContent[3].title]: pageContent[3].selectedValue,
                [pageContent[4].title]: pageContent[4].selectedValue,


            }
            setModuleActivityData(radioValues);

        } else {
            console.error("Invalid itemIndex:", itemIndex);
        }
    };


    const handlePostTestResults = (event, questionNumber) => {
        setPostTestResults((prevState) => ({
            ...prevState,
            [questionNumber]: event.target.value
        }));
        setModule7([
            {
                "According to WHO, what are Life Skills?": preTestResults.q1,
                "Which of the following is NOT one of the ten core Life Skills identified by WHO?": preTestResults.q2,
                "What does empathy refer to?": preTestResults.q3,
                "True or False: Self-awareness helps in recognizing stress and communicating effectively with others.": preTestResults.q4,
                "What is creative thinking about?": preTestResults,
            },

            {
                "How easy was it to go through course pages?": feedback.q1,
                "Was the module organized and structured properly?": feedback.q2,
                "Did the module increased your knowledge and skills in the subject matter?": feedback.q3,
                "How helpful did you find the module?": feedback.q4,
                "Rate your overall experience of the Module": feedback.q5,
            },
            {
                "According to WHO, what are Life Skills?": postTestResults.q1,
                "Which of the following is NOT one of the ten core Life Skills identified by WHO?": postTestResults.q2,
                "What does empathy refer to?": postTestResults.q3,
                "True or False: Self-awareness helps in recognizing stress and communicating effectively with others.": postTestResults.q4,
                "What is creative thinking about?": postTestResults.q5
            }
        ]);
    }

    const skillsList = [
        {
            title: "An individual is able to express oneself, both verbally and non-verbally",
            options: [
                {
                    value: "Communication skill",
                    label: "Communication skill"
                },
                {
                    value: "Managing emotions",
                    label: "Managing emotions"
                },
                {
                    value: "Creative thinking",
                    label: "Creative thinking"
                }
            ],
            selectedValue: pageContent[0].selectedValue,
        },
        {
            title: "________ skill helps you relate in positive ways with people we interact",
            options: [
                {
                    value: "Decision making",
                    label: "Decision making"
                },
                {
                    value: "Empathy",
                    label: "Empathy"
                },
                {
                    value: "Interpersonal skills",
                    label: "Interpersonal skills"
                }
            ],
            selectedValue: pageContent[1].selectedValue,
        },
        {
            title: "Developing _________ Skill can help us recognize when we are stressed or under pressure.",
            options: [
                {
                    value: "communication ",
                    label: "communication "
                },
                {
                    value: "Self awareness",
                    label: "Self awareness"
                },
                {
                    value: "stress management",
                    label: "stress management"
                }
            ],
            selectedValue: pageContent[2].selectedValue,
        },
        {
            title: "___________ skills helps in recognizing emotions within us and others",
            options: [
                {
                    value: "Critical thinking",
                    label: "Critical thinking"
                },
                {
                    value: "Managing emotions",
                    label: "Managing emotions"
                },
                {
                    value: "Decision making",
                    label: "Decision making"
                }
            ],
            selectedValue: pageContent[3].selectedValue,
        },
        {
            title: "_______________ skill helps in recognizing the sources of stress in our lives,",
            options: [
                {
                    value: "Coping with stress ",
                    label: "Coping with stress "
                },
                {
                    value: "Critical thinking",
                    label: "Critical thinking"
                },
                {
                    value: "Decision making",
                    label: "Decision making"
                }
            ],
            selectedValue: pageContent[4].selectedValue,
        }
    ];

    const testDetails = {
        q1: {
            ques: "According to WHO, what are Life Skills?",
            data: [
                {
                    value: "Abilities to deal with demands and challenges of everyday life",
                    label: "Abilities to deal with demands and challenges of everyday life"
                },
                {
                    value: "Knowledge and values about concerns",
                    label: "Knowledge and values about concerns"
                },
                {
                    value: "Abilities to live a fruitful life",
                    label: "Abilities to live a fruitful life"
                },
                {
                    value: "Learned abilities to do something well",
                    label: "Learned abilities to do something well"
                }
            ]
        },
        q2: {
            ques: "Which of the following is NOT one of the ten core Life Skills identified by WHO?",
            data: [
                {
                    value: "Self Awareness",
                    label: "Self Awareness"
                },
                {
                    value: "Critical Thinking",
                    label: "Critical Thinking"
                },
                {
                    value: "Empathy",
                    label: "Empathy"
                },
                {
                    value: "Time Management",
                    label: "Time Management"
                }
            ]
        },
        q3: {
            ques: "What does empathy refer to?",
            data: [
                {
                    value: "The ability to imagine life for another person",
                    label: "The ability to imagine life for another person"
                },
                {
                    value: "The ability to critically analyze information and experiences",
                    label: "The ability to critically analyze information and experiences"
                },
                {
                    value: "The ability to generate new ideas and be flexible",
                    label: "The ability to generate new ideas and be flexible"
                },
                {
                    value: "The ability to actively make decisions about actions",
                    label: "The ability to actively make decisions about actions"
                }
            ]
        },
        q4: {
            ques: "True or False: Self-awareness helps in recognizing stress and communicating effectively with others.",
            data: [
                {
                    value: "True",
                    label: "True"
                },
                {
                    value: "False",
                    label: "False"
                }
            ]
        },
        q5: {
            ques: "What is creative thinking about?",
            data: [
                {
                    value: "Analyzing information objectively",
                    label: "Analyzing information objectively"
                },
                {
                    value: "Recognizing and assessing factors that influence attitudes and behavior",
                    label: "Recognizing and assessing factors that influence attitudes and behavior"
                },
                {
                    value: "Identifying problems and applying solutions",
                    label: "Identifying problems and applying solutions"
                },
                {
                    value: "Seeing or doing things in a new way and generating new ideas",
                    label: "Seeing or doing things in a new way and generating new ideas"
                }
            ]
        }
    }

    const onModule7Submit = async () => {
        setActivityCompleted(true);
        try {
            let api = `${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

            let req_json = {
                "modulename": "module7",
                "testtype": "activity",
                "moduleData": {
                    moduleActivityData,
                    "How easy was it to go through course pages?": feedback.q1,
                    "Was the module organized and structured properly?": feedback.q2,
                    "Did the module increased your knowledge and skills in the subject matter?": feedback.q3,
                    "How helpful did you find the module?": feedback.q4,
                    "Rate your overall experience of the Module": feedback.q5,
                },
            }
            const response = await apiPostRequest(api, req_json);
        }
        catch {
            toastr.error("There is an internal error! Please logout and login.")
        }
    }

    const onModule7PreTestSubmit = async () => {
        setIsPreTestCompleted(true);
        const currentTime = localStorage.getItem("currentTime");
        try {
            let api = `${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

            let req_json = {
                "modulename": "module7",
                "testtype": "pretest",
                "moduleDataPretest": {
                    "According to WHO, what are Life Skills?": preTestResults.q1,
                    "Which of the following is NOT one of the ten core Life Skills identified by WHO?": preTestResults.q2,
                    "What does empathy refer to?": preTestResults.q3,
                    "True or False: Self-awareness helps in recognizing stress and communicating effectively with others.": preTestResults.q4,
                    "What is creative thinking about?": preTestResults.q5,
                },
                "currentTime": currentTime
            }
            const response = await apiPostRequest(api, req_json);
        }
        catch {
            toastr.error("There is an internal error! Please logout and login.")
        }
    }

    const onModule7PostTestSubmit = async () => {
        calculateUsedTime();
        const endTime = localStorage.getItem("currentTime");
        try {
            let api =`${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

            let req_json = {
                "modulename": "module7",
                "testtype": "posttest",
                "moduleData": {
                    "According to WHO, what are Life Skills?": postTestResults.q1,
                    "Which of the following is NOT one of the ten core Life Skills identified by WHO?": postTestResults.q2,
                    "What does empathy refer to?": postTestResults.q3,
                    "True or False: Self-awareness helps in recognizing stress and communicating effectively with others.": postTestResults.q4,
                    "What is creative thinking about?": postTestResults.q5
                },
                "endTime": endTime
            }
            const response = await apiPostRequest(api, req_json);
            if (response.data.success === true) {
                navigate('/user-home/activity/Module8');
            }
        }
        catch {
            toastr.error("There is an internal error! Please logout and login.")
        }
    }


    return (
        <>
            <ModulesMain notDefault={true} />
            {
                !isPreTestCompleted && (
                    <PrePostTest
                        testType={'PRE-TEST'}
                        testDetails={testDetails}
                        selectedValue={preTestResults}
                        onOptSelect={handlePreTestResults}
                        onTestSubmit={onModule7PreTestSubmit}
                        moduleNo={7}
                        btnClass={`col-md-1 col-sm-3 p-2 fw-bold ${areAllFieldsFilled(preTestResults)}`}
                    />
                )
            }

            {
                isPreTestCompleted && !isActivityCompleted && (
                    <div className="text-start module4-main-secton py-4 px-5">
                        <h1 className="mb-3 fw-bold">MODULE 7: Life Skills</h1>
                        <h4 className="fw-bold"> Introduction</h4>
                        <p>Life Skills are psychosocial abilities that enable individuals to translate knowledge, attitudes
                            and values regarding their concerns into well informed and healthy behaviours. Empowered with such
                            skills, young people are able to take decisions based on a logical process of "what to do, why to
                            do, how to do and when to do".
                        </p>

                        <h4 className="fw-bold"> What are Life Skills?</h4>

                        <p>A skill is a learned ability to do something well. So Life Skills are the abilities that individuals
                            can develop to live a fruitful life. </p>
                        <h5>Definition: </h5>
                        <ul>
                            <li>
                                WHO defines Life Skills as "the abilities for adaptive and positive behaviour that enable the
                                individuals to deal effectively with the demands and challenges of everyday life".
                            </li>
                        </ul>
                        <p> Here 'adaptive' means that a person is flexible in approach and is able to adjust in different
                            circumstances and 'positive behavior' implies that a person is forward looking and even in challenging situations, can find a ray of hope.</p>

                        <h4 className="fw-bold"> WHO has identified ten core Life Skills </h4>
                        <ol>
                            <li>Self Awareness</li>
                            <li>Empathy</li>
                            <li>Critical Thinking</li>
                            <li>Creative Thinking</li>
                            <li>Decision Making</li>
                            <li>Problem Solving</li>
                            <li>Interpersonal skills</li>
                            <li>Effective Communication</li>
                            <li>Coping with Stress</li>
                            <li>Coping with Emotions</li>
                        </ol>
                        <h4 className="fw-bold">1: Self Awareness </h4>
                        <ul>
                            <li>It is identifying oneself.</li>
                            <li>Identifying what are the strengths, weaknesses, desires, and dislikes of oneself.</li>
                            <li>Self-awareness can help us recognize when we are stressed or under pressure.</li>
                            <li>It further helps in communicating effectively with others and developing interpersonal relations.</li>
                        </ul>
                        <h4 className="fw-bold"> 2: Empathy:</h4>
                        <ul>
                            <li>It is the ability to imagine what life is like for another person.</li>
                            <li>It is required to develop a successful relationship with our loved ones.</li>
                            <li>It can help us to accept others, who may be very different from ourselves.</li>
                            <li>This can improve social interactions, especially, in situations of ethnic or cultural diversities.</li>
                        </ul>
                        <p>Eg: When your friend shares his/her problems, you understand them rather judging/ making fun about their problem.</p>
                        <h4 className="fw-bold"> 3: Critical Thinking</h4>
                        <ul>
                            <li>It is an ability to analyze information and experiences in an objective manner.</li>
                            <li>It helps us recognize and assess the factors that influence attitudes and behavior, such as values, peer pressure, and the media.</li>
                        </ul>
                        <p>Eg: What would you do if someone offers you to ride their bike?</p>
                        <h4 className="fw-bold">
                            4:Creative Thinking
                        </h4>
                        <ul>
                            <li>It is a new way of seeing or doing things.</li>
                            <li>It is about generating new ideas, being flexible to adopt new ideas, and building on others' ideas.</li>
                        </ul>
                        <p>Eg: coming up with new idea to prepare a new chart  on mental health for friends in school.</p>

                        <h4 className="fw-bold">
                            5:Decision Making
                        </h4>
                        <ul>
                            <li>It  teaches people how to actively make decisions about their actions  assess with different options available and, what effects these different decisions.
                            </li>
                        </ul>
                        <p>
                            Repeated and excessive worries about a variety of topics, events, or activities These worries are uncontrollable
                        </p>
                        <p>
                            Eg: After 10th std what you want to study? Arts, commerce or science?
                        </p>

                        <h4 className="fw-bold">
                            6:Problem Solving
                        </h4>
                        <ul>
                            <li>It  helps us to deal  problems in our lives. </li>
                            <li>It helps you identifying the problem, analyze it and apply best solution to it.</li>
                        </ul>
                        <p>Eg: making a time table for studying, to solve the problem of  managing time for various subjects.</p>
                        <h4 className="fw-bold">
                            7: Interpersonal skills
                        </h4>
                        <ul>
                            <li>It help us to relate in positive ways with people we interact. </li>
                            <li>It helps us keep friendly relationships with family members  and others.</li>
                        </ul>
                        <h4 className="fw-bold">
                            8:Effective Communication
                        </h4>
                        <ul>
                            <li>It means that we can express ourselves, both verbally and non-verbally, that are appropriate to our cultures and situations.</li>
                            <li>Able to express opinions and desires, needs, fears. and ask for help whenever needed</li>
                        </ul>
                        <p>Eg: You can share  with friends  how you deal with stress during exams</p>
                        <h4 className="fw-bold">
                            9: Coping with Stress
                        </h4>
                        <ul>
                            <li>It  means  you can recognize the various sources of stress</li>
                            <li>Knowing how it affects us and help us control the levels of stress</li>
                        </ul>
                        <p>Eg: Yoga might help you relax or may be doing some exercise.</p>


                        <h4 className="fw-bold"> 10: Managing Emotions </h4>
                        <ul>
                            <li>It means recognizing emotions within us and others,</li>
                            <li>It is  being aware of how emotions influence behaviour and being able to respond to emotions appropriately. </li>
                        </ul>

                        <h4 className="fw-bold">
                            4: Who needs Life Skills?</h4>
                        <ul>
                            <li>Everyone and anyone who wants to lead a meaningful life, needs Life Skills. </li>
                            <li>It is applicable to all ages of children and adolescents in schools </li>
                        </ul>

                        <h4 className="fw-bold"> Activity</h4>
                        <h5>Identify the skill:</h5>
                        {skillsList.map((item, itemIndex) => (

                            <>
                                <li key={itemIndex}>{item.title}</li>
                                <RadioOptions
                                    onChange={onAgreeDisagreePage4}

                                    selectedValue={item.selectedValue}
                                    name={item.title}
                                    data={item.options}
                                    qNo={itemIndex + 1}
                                />
                            </>
                        ))}
                        <h4 className="fw-bold"> Feedback :</h4>
                        <Feedback
                            selectedValue={feedback}
                            onOptSelect={handleFbRadioChange}
                            onSubmit={onModule7Submit}
                        />
                    </div>
                )
            }
            {
                isPreTestCompleted && isActivityCompleted && (
                    <PrePostTest
                        testType={'POST-TEST'}
                        testDetails={testDetails}
                        selectedValue={postTestResults}
                        onOptSelect={handlePostTestResults}
                        onTestSubmit={onModule7PostTestSubmit}
                        btnClass={`col-md-1 col-sm-3 p-2 fw-bold ${areAllFieldsFilled(postTestResults)}`}
                        moduleNo={7}
                    />
                )
            }
        </>
    )
}