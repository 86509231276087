import React, { useState, useEffect } from "react";
import ModulesMain from "../ModulesMain";
import PrePostTest from "../pre-post-test/PrePostTest";
import { Input } from "@mui/material";
import { areAllFieldsFilled } from "../../../utils/Tools";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import { apiPostRequest } from "../../../utils/Network";
import calculateUsedTime from "../../../utils/Tools";
import Feedback from "../feedback/Feedback";
import Popup from "../../../components/popup/Popup";
import Dialog from "../../../components/dialogue/Dialog";

export default function Module9() {
    const navigate = useNavigate();
    toastr.options = {
        closeButton: true,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
        timeOut: 3000,
    };

    const [feedback, setFeedback] = useState(
        {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: ""
        }
    )
    const [preTestResults, setPreTestResults] = useState(
        {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: ""
        }
    )
    const [postTestResults, setPostTestResults] = useState(
        {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: ""
        }
    )
    const [isPreTestCompleted, setIsPreTestCompleted] = useState(false)
    const [isActivityCompleted, setActivityCompleted] = useState(false)
    const [loader, setLoader] = useState(false);



    const apiCall = async () => {
        try {

            const api =`${process.env.REACT_APP_BASE_URL}/eachmodulestatus`;
            const req_json = { "modulename": "module9", "modulenumber": 9 }
            const response = await apiPostRequest(api, req_json);
            if (response.data.success == true) {
                const dataToPass = response.data.message["modulestatus"];
                if (dataToPass.pretest === 'completed') {
                    setIsPreTestCompleted(true);
                }
                if (dataToPass.pretest === 'completed' && dataToPass.activity === 'completed' && dataToPass.postTest === 'in-progress') {
                    setActivityCompleted(true);
                }
                if (dataToPass.pretest === 'completed' && dataToPass.activity === 'completed') {
                    setIsPreTestCompleted(true);
                    setActivityCompleted(true);
                }
                if (dataToPass.pretest === "completed" && dataToPass.activity === "completed" && dataToPass.posttest === "completed") {
                    navigate("/user-home/activity");
                }
            } else {
                toastr.error(response);
            }
        }
        catch {
            toastr.error("There is an internal error! Please logout and login.")
        }
    }

    useEffect(() => {
        const modulestatus = JSON.parse(localStorage.getItem("all_module_status"))
        if (modulestatus[7].status === 'completed') {
            apiCall();
        } else {
            navigate('/user-home/activity')
        }
    }, []);


    const handleFbRadioChange = (event, questionNumber) => {
        setFeedback((prevState) => ({
            ...prevState,
            [questionNumber]: event.target.value
        }));
        setModule9([
            {
                "What is the reason behind saying 'Yes' when one should be saying 'No'?": preTestResults.q1,
                "Why do adolescents often say 'Yes' when they recognize they should say 'No'?": preTestResults.q2,
                "What are some examples of high-risk behaviors that adolescents may engage in?": preTestResults.q3,
                "True or False: It is important to recognize, respect, and accept the ideas and rights of others.": preTestResults.q4,
                "What is the difference between being assertive and being aggressive?": preTestResults.q5
            },

            {
                situation1Answers,
                situation2Answers,
                assignmentAnswers,
                "How easy was it to go through course pages?": feedback.q1,
                "Was the module organized and structured properly?": feedback.q2,
                "Did the module increased your knowledge and skills in the subject matter?": feedback.q3,
                "How helpful did you find the module?": feedback.q4,
                "Rate your overall experience of the Module": feedback.q5,
            },
            {
                "What is the reason behind saying 'Yes' when one should be saying 'No'?": postTestResults.q1,
                "Why do adolescents often say 'Yes' when they recognize they should say 'No'?": postTestResults.q2,
                "What are some examples of high-risk behaviors that adolescents may engage in?": postTestResults.q3,
                "True or False: It is important to recognize, respect, and accept the ideas and rights of others.": postTestResults.q4,
                "What is the difference between being assertive and being aggressive?": postTestResults.q5
            }
        ]);
    };
    const handlePreTestResults = (event, questionNumber) => {
        setPreTestResults((prevState) => ({
            ...prevState,
            [questionNumber]: event.target.value
        }));
    }

    const handlePostTestResults = (event, questionNumber) => {
        setPostTestResults((prevState) => ({
            ...prevState,
            [questionNumber]: event.target.value
        }));
        setModule9([
            {
                "What is the reason behind saying 'Yes' when one should be saying 'No'?": preTestResults.q1,
                "Why do adolescents often say 'Yes' when they recognize they should say 'No'?": preTestResults.q2,
                "What are some examples of high-risk behaviors that adolescents may engage in?": preTestResults.q3,
                "True or False: It is important to recognize, respect, and accept the ideas and rights of others.": preTestResults.q4,
                "What is the difference between being assertive and being aggressive?": preTestResults.q5
            },

            {
                situation1Answers,
                situation2Answers,
                assignmentAnswers,
                "How easy was it to go through course pages?": feedback.q1,
                "Was the module organized and structured properly?": feedback.q2,
                "Did the module increased your knowledge and skills in the subject matter?": feedback.q3,
                "How helpful did you find the module?": feedback.q4,
                "Rate your overall experience of the Module": feedback.q5,
            },
            {
                "What is the reason behind saying 'Yes' when one should be saying 'No'?": postTestResults.q1,
                "Why do adolescents often say 'Yes' when they recognize they should say 'No'?": postTestResults.q2,
                "What are some examples of high-risk behaviors that adolescents may engage in?": postTestResults.q3,
                "True or False: It is important to recognize, respect, and accept the ideas and rights of others.": postTestResults.q4,
                "What is the difference between being assertive and being aggressive?": postTestResults.q5
            }
        ]);
    }

    const activityQuestions = {
        situation1: [
            "how do other students behave with Pramod?",
            "what  was the challenge given to Pramod by the other boys?",
            "Did Pramod’s friends help him?",
            "What did Pramod regret ?"
        ],
        situation2: [
            "How many best friends did Sharayu have ?",
            "From where does Sharyau steals money ?",
            "Who sees sharayu coming out of the hotel? "
        ]
    }

    const [situation1Answers, setSituation1Answers] = useState({});
    const [situation2Answers, setSituation2Answers] = useState({});
    const [assignmentAnswers, setAssignmentAnswers] = useState(['', '', '', '', '']);
    const [module9, setModule9] = useState([]);



    const handleAssignmentAnswers = (index, value) => {
        const newAnswers = [...assignmentAnswers];
        newAnswers[index] = value;
        setAssignmentAnswers(newAnswers);
    };

    const updateSituation1Answers = (question, answer) => {
        setSituation1Answers((prevAnswers) => ({
            ...prevAnswers,
            [question]: answer,
        }));
    };

    const updateSituation2Answers = (question, answer) => {
        setSituation2Answers((prevAnswers) => ({
            ...prevAnswers,
            [question]: answer,
        }));
    };

    const testDetails = {
        q1: {
            ques: "What is the reason behind saying 'Yes' when one should be saying 'No'?",
            data: [
                {
                    value: "Lack of assertive skills",
                    label: "Lack of assertive skills"
                },
                {
                    value: "Fear of pleasing others",
                    label: "Fear of pleasing others"
                },
                {
                    value: "Lack of confidence",
                    label: "Lack of confidence"
                },
                {
                    value: "Lack of conviction in one's thoughts and needs",
                    label: "Lack of conviction in one's thoughts and needs"
                }
            ]
        },
        q2: {
            ques: "Why do adolescents often say 'Yes' when they recognize they should say 'No'?",
            data: [
                {
                    value: "To prove their individuality",
                    label: "To prove their individuality"
                },
                {
                    value: "Fear of high-risk behaviors",
                    label: "Fear of high-risk behaviors"
                },
                {
                    value: "Lack of clarity in attitudes",
                    label: "Lack of clarity in attitudes"
                },
                {
                    value: "Sensation-seeking behaviors",
                    label: "Sensation-seeking behaviors"
                }
            ]
        },
        q3: {
            ques: "What are some examples of high-risk behaviors that adolescents may engage in?",
            data: [
                {
                    value: "Bunking classes to see movies",
                    label: "Bunking classes to see movies"
                },
                {
                    value: "Ragging and teasing girls/younger persons",
                    label: "Ragging and teasing girls/younger persons"
                },
                {
                    value: "Smoking, drinking alcohol",
                    label: "Smoking, drinking alcohol"
                },
                {
                    value: "All of the above",
                    label: "All of the above"
                }
            ]
        },
        q4: {
            ques: "True or False: It is important to recognize, respect, and accept the ideas and rights of others.",
            data: [
                {
                    value: "True",
                    label: "True"
                },
                {
                    value: "False",
                    label: "False"
                }
            ]
        },
        q5: {
            ques: "What is the difference between being assertive and being aggressive?",
            data: [
                {
                    value: "Being assertive respects the rights of others, while being aggressive violates them",
                    label: "Being assertive respects the rights of others, while being aggressive violates them"
                },
                {
                    value: "Being assertive involves intruding into the rights of others",
                    label: "Being assertive involves intruding into the rights of others"
                },
                {
                    value: "Being aggressive establishes clear ideas and needs",
                    label: "Being aggressive establishes clear ideas and needs"
                },
                {
                    value: "Being assertive leads to the breakdown of relationships",
                    label: "Being assertive leads to the breakdown of relationships"
                }
            ]
        }
    }


    const onModule9Submit = async () => {
        setActivityCompleted(true);
        try {
            let api = `${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

            let req_json = {
                "modulename": "module9",
                "testtype": "activity",
                "moduleData": {
                    situation1Answers,
                    situation2Answers,
                    assignmentAnswers,
                    "How easy was it to go through course pages?": feedback.q1,
                    "Was the module organized and structured properly?": feedback.q2,
                    "Did the module increased your knowledge and skills in the subject matter?": feedback.q3,
                    "How helpful did you find the module?": feedback.q4,
                    "Rate your overall experience of the Module": feedback.q5,
                },
            }
            const response = await apiPostRequest(api, req_json);
            
        }
        catch {
            toastr.error("There is an internal error! Please logout and login.")
        }
    }

    const onModule9PreTestSubmit = async () => {
        setIsPreTestCompleted(true);
        const currentTime = localStorage.getItem("currentTime");
        try {
            let api = `${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

            let req_json = {
                "modulename": "module9",
                "testtype": "pretest",
                "moduleDataPretest": {
                    "What is the reason behind saying 'Yes' when one should be saying 'No'?": preTestResults.q1,
                    "Why do adolescents often say 'Yes' when they recognize they should say 'No'?": preTestResults.q2,
                    "What are some examples of high-risk behaviors that adolescents may engage in?": preTestResults.q3,
                    "True or False: It is important to recognize, respect, and accept the ideas and rights of others.": preTestResults.q4,
                    "What is the difference between being assertive and being aggressive?": preTestResults.q5
                },
                "currentTime": currentTime
            }
            const response = await apiPostRequest(api, req_json);
        }
        catch {
            toastr.error("There is an internal error! Please logout and login.")
        }
    }


    const onModule9PostTestSubmit = async () => {
        calculateUsedTime();
        const endTime = localStorage.getItem("currentTime");
        try {
            setLoader(true);
            let api = `${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

            let req_json = {
                "modulename": "module9",
                "testtype": "posttest",
                "moduleData": {
                    "What is the reason behind saying 'Yes' when one should be saying 'No'?": postTestResults.q1,
                    "Why do adolescents often say 'Yes' when they recognize they should say 'No'?": postTestResults.q2,
                    "What are some examples of high-risk behaviors that adolescents may engage in?": postTestResults.q3,
                    "True or False: It is important to recognize, respect, and accept the ideas and rights of others.": postTestResults.q4,
                    "What is the difference between being assertive and being aggressive?": postTestResults.q5
                },
                "endTime": endTime
            }
            const response = await apiPostRequest(api, req_json);
            if (response.data.success === true) {


                navigate('/activity-results');
                setLoader(false);
                
            }
        }
        catch {
            toastr.error("There is an internal error! Please logout and login.")
        }



    }


    return (
        <>
            <ModulesMain notDefault={true} />
            {
                !isPreTestCompleted && (
                    <PrePostTest
                        testType={'PRE-TEST'}
                        testDetails={testDetails}
                        selectedValue={preTestResults}
                        onOptSelect={handlePreTestResults}
                        onTestSubmit={onModule9PreTestSubmit}
                        btnClass={`col-md-1 col-sm-3 p-2 fw-bold ${areAllFieldsFilled(preTestResults)}`}
                        moduleNo={9}
                    />
                )
            }

            {
                isPreTestCompleted && !isActivityCompleted && (
                    <div className="text-start module4-main-secton py-4 px-5">
                        <h1 className="mb-3 fw-bold">MODULE 9: Peer Pressure</h1>
                        <h4 className="fw-bold"> Introduction</h4>
                        <p>Saying ‘YES’ when one should be saying ‘NO’ is due to lack of Assertive Skills. In other words one is not able to say clearly what he/she really wants to say.
                            Assertion is when an individual is convinced about her/his thoughts, attitudes, needs, desires and behavior; puts them into practice to protect one’s own interests and rights.
                        </p>
                        <h5>Often one says ‘Yes’ when he/she should be saying ‘No’ for various reasons like,</h5>
                        <ul>
                            <li>Need to please the other person.</li>
                            <li>Fear of being hurt by saying 'No'.</li>
                            <li>Fear of the relationship coming to an end.</li>
                            <li>Lack of confidence.</li>
                            <li>Lack of conviction in one's own thoughts and needs.</li>
                        </ul>

                        <h4 className="fw-bold"> Why do we need to learn to say a no??</h4>
                        <ul>
                            <li>Adolescence is the period when youngsters are pressurized by peers into various behaviors and habits. In their need to prove their individuality and solidarity with peers, adolescents usually say ‘Yes’ even when they recognize they have to say ‘No’.</li>
                            <li>Lack of such skills lead adolescents into:</li>
                            <ul>
                                <li>High-Risk Behaviors like drug use, rebelling against values and other sensation seeking behaviors.</li>
                                <li>Smoking, drinking alcohol, bunking classes to see movies, disobedience in the classroom, ragging and teasing girls/younger persons, gambling, visiting dangerous places, which adolescents indulge in, due to lack of clarity in attitudes and poor assertive skills.</li>
                            </ul>
                        </ul>

                        <h4 className="fw-bold">Adolescents have to differentiate between ‘Being Assertive’ and ‘Being Aggressive’.</h4>

                        <h5>Being Assertive’: </h5>
                        <p> In assertion one is clear about his/her ideas, needs, rights and establishes them clearly in a manner that will be listened to by others. </p>
                        <h5>Being Aggressive’:</h5>
                        <p>In aggression, one is intruding into the rights of others forcibly leading to breaking down of the relationship.</p>
                        <p> Just as one has ideas and rights, others also have rights and ideas and one has to recognize respect and accept them. </p>

                        <h4 className="fw-bold"> Activity 1:Friends or Foe?</h4>
                        <h5>Objectives</h5>
                        <ul>
                            <li>To facilitate development of assertive skills in students.</li>
                            <li>To use the assertive skills when necessary especially when there is unwanted peer pressure.</li>
                        </ul>
                        <h5>Situation – 1 </h5>
                        <p>
                            Pramod is a very shy but a bright student. He is hard working, obedient and soft spoken. He has very few friends and most often he is the subject of jokes in his class as he is considered a ‘goody goody boy’. He is teased and ragged all the time, which makes him feel isolated and rejected.
                        </p>
                        <p>
                            Pramod spoke about this with his parents and they told him that he should be brave and courageous but did not tell him what he should do about it. The feeling of isolation at school bothers Pramod a lot. He tries a lot to be friendly with his classmates. No matter how hard he tries he is pushed aside and teased. One day when he approaches his classmates they challenge him to prove his becoming a ‘Man’ by giving a love letter to one of his class girls. They promise to make him a part of their group if he does so. Pramod initially refuses. The boys repeatedly challenge him. After days of bargaining, Pramod takes a decision to do what the boys have instructed. He writes a love letter to one of the class girls, shows it to the classmates, and in their presence walks up to the girl and gives it to her.
                        </p>
                        <p>
                            Pramod realizes that despite their promise to become his friends, they are not extending a helping hand though he gave into their pressure. He regrets that he had not thought through the whole act well, but focused only on becoming a part of the gang.
                        </p>

                        <h4 className="fw-bold"> ANSWER THE FOLLOWING : </h4>

                        {activityQuestions.situation1.map((question, index) => (
                            <div key={index}>
                                <p className="m-0">Q{index + 1}: {question}</p>
                                Ans: <Input
                                    className="mb-3 col-md-11"
                                    type="text"
                                    onChange={(e) => updateSituation1Answers(question, e.target.value)}
                                />
                            </div>
                        ))}

                        <h4 className="fw-bold">
                            Situation – 2
                        </h4>

                        <p>
                            Sarayu is a very bold and intelligent girl of 16 years in X standard. She comes from a poor family. Her parents work as manual labourers and send the children to school. Sarayu is aware of the difficulty of the family and behaves in a responsible way. She is in the Class Squad - II and is very popular among the squad members. One day four girls who are best friends of Sarayu plan to cut class and go to a movie and later to a hotel to celebrate before leaving school.
                        </p>
                        <p>
                            Sarayu expresses inability to participate due to lack of money. The girls plead with her repeatedly to somehow join them as this would be their last outing together in school life.
                        </p>
                        <p>
                            Sarayu knows the poverty at home but is lured by the argument of the friends. The friends do not have money to spare. So Sarayu steals money from the God’s Hundi, which she plans to put back, whenever she gets money. She is also convinced that nobody will notice the loss of money from the Hundi because no one checks it.
                        </p>
                        <p>
                            She goes to the movie and later to the hotel. When she comes out of the hotel, she sees her father unloading rice bags from a lorry for the hotel. Her father also sees her coming out of the hotel with her friends. When father demands an explanation for Sarayu’s behavior she argues and shouts at him that she has a ‘right to have good time like other girls’. Also adds that there is nothing wrong with her behavior of using the money from the Hundi which was any how not being used by anyone.
                        </p>

                        <h4 className="fw-bold">
                            ANSWER THE FOLLOWING
                        </h4>
                        {activityQuestions.situation2.map((question, index) => (
                            <div key={index}>
                                <p className="m-0">Q{index + 1}: {question}</p>
                                Ans: <Input
                                    className="mb-3 col-md-11"
                                    type="text"
                                    onChange={(e) => updateSituation2Answers(question, e.target.value)}
                                />
                            </div>
                        ))}

                        <h4 className="fw-bold">
                            Assignment: Name some of the situations when you need to be assertive ?
                        </h4>
                        <ol>
                            {assignmentAnswers.map((answer, index) => (
                                <li key={index}>
                                    <Input
                                        className="col-md-8 px-2"
                                        type="text"
                                        placeholder={`situation ${index + 1}`}
                                        value={answer}
                                        onChange={(e) => handleAssignmentAnswers(index, e.target.value)}
                                    />
                                </li>
                            ))}
                        </ol>

                        <h4 className="fw-bold"> Feedback :</h4>
                        <Feedback
                            selectedValue={feedback}
                            onOptSelect={handleFbRadioChange}
                            onSubmit={onModule9Submit}
                        />
                    </div>
                )
            }
            {
                isPreTestCompleted && isActivityCompleted && (
                    <PrePostTest
                        testType={'POST-TEST'}
                        testDetails={testDetails}
                        selectedValue={postTestResults}
                        onOptSelect={handlePostTestResults}
                        onTestSubmit={onModule9PostTestSubmit}
                        btnClass={`col-md-1 col-sm-3 p-2 fw-bold ${areAllFieldsFilled(postTestResults)}`}
                        moduleNo={9}
                    />
                )
            }


        </>
    )
}