import React, { useEffect, useState } from "react";
import edited_certificate from "../../images/edited_certificate.jpg";
import original_certificate from "../../images/CertificateIMG.jpg";
import { apiGetRequest, apiPostRequest } from "../../utils/Network";
import Loader from "../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";
import "./Certificate.css";

export default function Certificate({ handleClose }) {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [edit, setEdit] = useState(false);
    const [loader, setLoader] = useState(false);
    const [downloadImg, setDownloadImg] = useState("");

    const handleInput = (event) => {
        setName(event.target.value);
    };

    const apiCallCheck = async () => {
        const api = `${process.env.REACT_APP_BASE_URL}/user_list`;
        try {
            const response = await apiGetRequest(api);
            if (response.data.success) {
                const message = response.data.message;
                const certificate_downloaded = message.some(student => student.certificate_downloaded);
                return certificate_downloaded;

            } else {
                toastr.error("Failed to fetch student list");
            }
        } catch (error) {
            console.error("Error in apiCall:", error);
            toastr.error("An error occurred while fetching student list");
        }
    };

    useEffect(() => {
        apiCallCheck();
    }, [])

    const apiCall = async () => {
        setLoader(true);
        let api = `${process.env.REACT_APP_BASE_URL}/edit_certificate`;
        let req_json = {
            "name": name
        }

        const response = await apiPostRequest(api, req_json, { responseType: 'blob' });
        if (response) {


            setTimeout(() => {
                setLoader(false);
                setEdit(true);
                setDownloadImg(response.data.file_url);
            }, 1700);
        } else {
            toastr.error(response.data.message)
        }

    };

    const cerificate_state_store = async () => {
        const api = `${process.env.REACT_APP_BASE_URL}/cerificate_state_store`;
        const req_json = {
            "certificate_downloaded": true
        }

        const response = await apiPostRequest(api, req_json)

    }


    const handleSubmit = async (event) => {
        try {
            event.preventDefault();

            const api = `${process.env.REACT_APP_BASE_URL}/edited_image`;

            const response = await fetch(api);

            if (!response.ok) {
                localStorage.setItem("certificate", false)

                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const blob = await response.blob();

            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = 'edited_certificate.jpg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            localStorage.setItem("certificate", true)
            cerificate_state_store()
            navigate("/activity-results");
            handleClose();

        } catch (error) {
            console.error("Error:", error);
            toastr.error("An error occurred while processing your request");
        }
    };


    return (
        <>
            {loader ? <Loader /> : (<></>)}
            <div className="certificate">
                <div className="meta col-md-6" style={{ alignContent: "space-evenly" }}>
                    <p>Please Enter Your Name</p>
                    <input
                        type="text"
                        style={{ padding: "7px" }}
                        value={name}
                        onChange={handleInput}
                    />

                    <button
                        className={edit ? "buttonDownload" : "buttonEdit"}
                        onClick={edit ? handleSubmit : apiCall}
                    >
                        {edit ? 'Download' : 'Edit'}
                    </button>
                </div>
                {edit ?

                    <img alt="Certificate" src={edited_certificate} style={{ width: "100%", height: "100%" }} />
                    :
                    <img alt="Certificate" src={original_certificate} style={{ width: "100%", height: "100%" }} />
                }
            </div>
        </>
    );
}
