import React, { useEffect, useState } from "react";
import doctorImage from "../../../images/LoginDoctorImage.jpg";
import DynamicLayout from "../../dynamic-layout/DynamicLayout";
import "../Login.css";
import { useNavigate } from "react-router-dom";
import { apiPostRequest } from "../../../utils/Network";
import toastr from "toastr";
import Cookies from "js-cookie";
import "toastr/build/toastr.min.css";
import Loader from "../../../components/loader/Loader";
import Dialog from "../../../components/dialogue/Dialog";

export default function UserLogin() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [userInputs, setUserInputs] = useState({
    phonenumber: "",
    password: "",
  });

  useEffect(() => {
    setOpen(!open);
  }, []);

  const handleInputs = (e) => {
    const { id, value } = e.target;
    setUserInputs((prevInputs) => ({
      ...prevInputs,
      [id]: value,
    }));
  };

  const onLogin = async () => {
    setLoader(true);
    let mobileNumberFrmt = /^\+(?:[0-9]{12})$|^[0-9]{10}$/;
    let passwordFrmt =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (userInputs.phonenumber) {
      try {
        let api = `${process.env.REACT_APP_BASE_URL}/studentlogin`;
        let req_json = {
          mobileno: userInputs.phonenumber,
          password: userInputs.password,
        };

        const response = await apiPostRequest(api, req_json);
        if (response.data.success === true) {
          setTimeout(() => {
            localStorage.setItem("isUserLoggedIn", true);
            Cookies.set("auth_token", response.data.token);
            setLoader(false);
            navigate("/user-home");
          }, 1500);
        } else {
          setLoader(false);
          toastr.info(response.data.message);
        }
      } catch {
        toastr.error("There is an internal error! Please logout and login.");
      }
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("isUserLoggedIn")) === true) {
      navigate("/user-home");
    }
  }, [navigate]);

  return (
    <>
      <div style={{ float: "right", fontSize: "large" }} className="m-1">
        <button
          style={{
            border: "none",
            backgroundColor: "white",
            color: "grey",
            textDecoration: "underline",
          }}
          onClick={() => navigate("/signup")}
        >
          Signup
        </button>
      </div>

      <br />
      <div className="mt-5">
        <DynamicLayout header={"Student Login"} layout={true}>
          {loader && <Loader />}
          <div className="col-md-6 loginforTab">
            <img src={doctorImage} style={{ width: "100%", height: "100%" }} />
          </div>
          <div className="col-md-6 p-4 loginforTab">
            <input
              className="textfield"
              onChange={handleInputs}
              type="number"
              id="phonenumber"
              value={userInputs.phonenumber}
              placeholder="Phone Number"
              style={{
                marginTop: "4%",
                width: "50vh",
                borderRadius: "22px",
                padding: "8px",
                border: "1px solid grey",
              }}
            />
            <input
              className="textfield"
              onChange={handleInputs}
              type="password"
              id="password"
              value={userInputs.password}
              placeholder="Password"
              style={{
                marginTop: "4%",
                width: "50vh",
                borderRadius: "22px",
                padding: "8px",
                border: "1px solid grey",
              }}
            />
            <br />
            <button
              className="loginBtn"
              disabled={
                userInputs.phonenumber && userInputs.password ? false : true
              }
              onClick={onLogin}
            >
              Login
            </button>
          </div>

          {open && (
            <Dialog
              handleClose={() => setOpen(false)}
              open={open}
              title={"Dear Students,"}
              content={
                "To login kindly enter the same phone number provided while registration"
              }
            />
          )}
        </DynamicLayout>
      </div>
    </>
  );
}
