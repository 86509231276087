import React, { useState, useEffect, useRef } from "react";
import ModulesMain from "../ModulesMain";
import "./Module.css";
import RadioOptions from "../../../components/radiogroup/RadioOptions";
import CustomTable from "../../../components/table/CustomTable";
import Button from "../../../components/button/Button";
import InputField from "../../../components/input/InputField";
import PrePostTest from "../pre-post-test/PrePostTest";
import { areAllFieldsFilled } from "../../../utils/Tools";
import { apiPostRequest } from "../../../utils/Network";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import Dialog from "../../../components/dialogue/Dialog";
import calculateUsedTime from "../../../utils/Tools";

export default function Module1() {
  const navigate = useNavigate();
  toastr.options = {
    closeButton: true,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    timeOut: 3000,
  };
  const [pretest, setPretest] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
  });


  const [childrenAdolence, setChildrenAdolence] = useState({
    Q1: "",
    Q2: "",
  });

  const [radiofeedback, setRadioFeedback] = useState({
    Q1: "",
    Q2: "",
    Q3: "",
    Q4: "",
    Q5: "",
  });
  const [postTestEnabled, setPostTestEnabled] = useState('false');

  const [postTest, setPostTest] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
  });


  const [commonIssue, setCommonIssue] = useState({
    I1: "",
    I2: "",
    I3: "",
  });
  const [isPreTestCompleted, setIsPreTestCompleted] = useState(false);


  const [isPostTestEnabled, setIsPostTestEnabled] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [mentalHealthChallenges, setMentalHealthChallenges] = useState({
    T1: "",
    T2: "",
    T3: "",
  });

  const [mentalHealthIssues, setMentalHealthIssues] = useState({
    T1: "",
    T2: "",
    T3: "",
  });
  const [riskFactorsActivity, setRiskFactorActivity] = useState({
    "Sex and Age": "",
    "Family history of psychiatric and developmental disorders": "",
    "Poverty, natural disasters, migration, war and violence": "",
    "Neighborhood, school, peers, high parental expectations": "",
  });

  const count = useRef(0);

  const apiCall = async () => {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}/eachmodulestatus`;

      const req_json = {
        "modulename": "module1",
        "modulenumber": 1
      }
      const response = await apiPostRequest(api, req_json);

      if (response.data.success == true) {
        const dataToPass = response.data.message["modulestatus"];
        if (dataToPass.pretest === 'Yet to start') {
          navigate("/user-home/activity");
        } else {
          if (dataToPass.pretest === 'completed') {
            setIsPreTestCompleted(true);
          }
          if (dataToPass.pretest === 'completed' && dataToPass.activity === 'in-progress') {
            setIsPreTestCompleted(true);
            setIsPostTestEnabled(false);
          }
          if (dataToPass.pretest === 'completed' && dataToPass.activity === 'completed') {
            setIsPostTestEnabled(true);
          }
          if (dataToPass.pretest === "completed" && dataToPass.activity === "completed" && dataToPass.posttest === "completed") {
            navigate("/user-home/activity");
          }
        }


      } else {
        toastr.error(response.msg);
      }
    }
    catch {
      toastr.error("There is an internal error! Please logout and login.")
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {


    if (count.current === 0) {
      apiCall();
      count.current = count.current + 1;

    }
    setOpen(!open);


  }, []);

  const childrenAdolenceData = [
    {
      title:
        "Approximately how much our national population constitute Children and adolescents",
      selectedValue: childrenAdolence.Q1,
      qNo: "Q1",
      radioLabels: {
        labels: [
          {
            value: "30%",
            label: "30%",
          },
          {
            value: "40%",
            label: "40%",
          },
          {
            value: "50%",
            label: "50%",
          },
          {
            value: "60%",
            label: "60%",
          },
        ],
      },
      name: "ca1",
    },
    {
      title: "Overall how much children are in need of mental health services?",
      selectedValue: childrenAdolence.Q2,
      qNo: "Q2",
      radioLabels: {
        labels: [
          {
            value: "8-13%",
            label: "8-13%",
          },
          {
            value: "8-14%",
            label: "8-14%",
          },
          {
            value: "8-15%",
            label: "8-15%",
          },
          {
            value: "8-16%",
            label: "8-16%",
          },
        ],
      },
      name: "ca2",
    },
  ];

  const radioValues = [
    {
      feedback_title: "How easy  was it to go through course pages?",
      selectedValue: radiofeedback.Q1,
      qNo: "Q1",
      radioLabels: {
        labels: [
          {
            value: "Very Easy",
            label: "Very Easy",
          },
          {
            value: "Easy",
            label: "Easy",
          },
          {
            value: "Neutral",
            label: "Neutral",
          },
          {
            value: "Difficult",
            label: "Difficult",
          },
          {
            value: "Very Difficult",
            label: "Very Difficult",
          },
        ],
      },
      name: "radio1",
    },
    {
      feedback_title: "Was the module organized and structured properly?",
      selectedValue: radiofeedback.Q2,
      qNo: "Q2",
      radioLabels: {
        labels: [
          {
            value: "Strongly agree",
            label: "Strongly agree",
          },
          {
            value: "Agree",
            label: "Agree",
          },
          {
            value: "Neutral",
            label: "Neutral",
          },
          {
            value: "Neither agree or disagree",
            label: "Neither agree or disagree",
          },
          {
            value: "Disagree",
            label: "Disagree",
          },
          {
            value: "Strongly disagree",
            label: "Strongly disagree",
          },
        ],
      },
      name: "radio2",
    },

    {
      feedback_title:
        "Did the module increased your knowledge and skills in the subject matter?",
      selectedValue: radiofeedback.Q3,
      qNo: "Q3",
      radioLabels: {
        labels: [
          {
            value: "Strongly agree",
            label: "Strongly agree",
          },
          {
            value: "Agree",
            label: "Agree",
          },
          {
            value: "Neutral",
            label: "Neutral",
          },
          {
            value: "Neither agree or disagree",
            label: "Neither agree or disagree",
          },
          {
            value: "Disagree",
            label: "Disagree",
          },
          {
            value: "Strongly disagree",
            label: "Strongly disagree",
          },
        ],
      },
      name: "radio3",
    },
    {
      feedback_title: "How helpful did you find the module?",
      selectedValue: radiofeedback.Q4,
      qNo: "Q4",
      radioLabels: {
        labels: [
          {
            value: "Very helpful",
            label: "Very helpful",
          },
          {
            value: "Helpful",
            label: "Helpful",
          },
          {
            value: "Undecided",
            label: "Undecided",
          },
          {
            value: "Not helpful",
            label: "Not helpful",
          },
        ],
      },
      name: "radio4",
    },
    {
      feedback_title: "Rate your overall experience of the Module?",
      selectedValue: radiofeedback.Q5,
      qNo: "Q5",
      radioLabels: {
        labels: [
          {
            value: "Very Good",
            label: "Very Good",
          },
          {
            value: "Good",
            label: "Good",
          },
          {
            value: "Acceptable",
            label: "Acceptable",
          },
          {
            value: "Poor",
            label: "Poor",
          },
          {
            value: "Very Poor",
            label: "Very Poor",
          },
        ],
      },
      name: "radio5",
    },
  ];


  const post_test_data = {
    q1: {
      ques: "What is the definition of mental health?",
      data: [
        {
          value: "A state of physical well-being",
          label: "A state of physical well-being",
        },
        {
          value: "A state of emotional well-being",
          label: "A state of emotional well-being",
        },
        {
          value: "A state of mental and emotional well-being",
          label: "A state of mental and emotional well-being",
        },
        {
          value: "A state of social well-being",
          label: "A state of social well-being",
        },
      ],
    },

    q2: {
      ques: "Approximately what percentage of the national population constitutes children and adolescents?",
      data: [
        {
          value: "10%",
          label: "10%",
        },
        {
          value: "25%",
          label: "25%",
        },
        {
          value: "40%",
          label: "40%",
        },
        {
          value: "60%",
          label: "60%",
        },
      ],
    },

    q3: {
      ques: "According to the National Mental Health Survey, what is the prevalence of mental health issues in children and adolescents aged 13-17 years?",
      data: [
        {
          value: "3.5%",
          label: "3.5%",
        },
        {
          value: "5.7%",
          label: "5.7%",
        },
        {
          value: "7.3%",
          label: "7.3%",
        },
        {
          value: "9.1%",
          label: "9.1%",
        },
      ],
    },

    q4: {
      ques: "Which of the following is NOT a common mental health issue faced by children and adolescents?",
      data: [
        {
          value: "Stress",
          label: "Stress",
        },
        {
          value: "Anxiety",
          label: "Anxiety",
        },
        {
          value: "Depression",
          label: "Depression",
        },
        {
          value: "Diabetes",
          label: "Diabetes",
        },
      ],
    },

    q5: {
      ques: "Which of the following is NOT considered a risk factor for the development of mental health issues in children?",
      data: [
        {
          value: "Family history of psychiatric and developmental disorders",
          label: "Family history of psychiatric and developmental disorders",
        },
        {
          value: "Poverty",
          label: "Poverty",
        },
        {
          value: "High parental expectations",
          label: "High parental expectations",
        },
        {
          value: "Good academic performance",
          label: "Good academic performance",
        },
      ],
    },
  };



  const handleAdolescentRadioChange = (e, questionNumber) => {
    const name = e.target.name;
    const startsWithPT = /^pt/i.test(name);
    const startsWithCA = /^ca/i.test(name);
    const startsWithRA = /^ra/i.test(name);
    const startsWithPO = /^po/i.test(name);
    if (startsWithPT) {
      setPretest((prevState) => ({
        ...prevState,
        [e.target.ques]: e.target.data,

      }));
    }
    if (startsWithCA) {
      setChildrenAdolence((prevState) => ({
        ...prevState,
        [questionNumber]: e.target.value,
      }));
    }
    if (startsWithRA) {
      setRadioFeedback((prevState) => ({
        ...prevState,
        [questionNumber]: e.target.value,
      }));
    }
    if (startsWithPO) {
      setPostTest((prevState) => ({
        ...prevState,
        [questionNumber]: e.target.value,
      }));
    }
  };

  const commonIssueTrack = (e) => {
    if (e.target.id === "commonissue1") {
      setCommonIssue((prevState) => ({
        ...prevState,
        I1: e.target.value,
      }));
    }
    if (e.target.id === "commonissue2") {
      setCommonIssue((prevState) => ({
        ...prevState,
        I2: e.target.value,
      }));
    }
    if (e.target.id === "commonissue3") {
      setCommonIssue((prevState) => ({
        ...prevState,
        I3: e.target.value,
      }));
    }
    if (e.target.id === "mentalhealthchallenges1") {
      setMentalHealthChallenges((prevState) => ({
        ...prevState,
        T1: e.target.value,
      }));
    }
    if (e.target.id === "mentalhealthchallenges2") {
      setMentalHealthChallenges((prevState) => ({
        ...prevState,
        T2: e.target.value,
      }));
    }
    if (e.target.id === "mentalhealthchallenges3") {
      setMentalHealthChallenges((prevState) => ({
        ...prevState,
        T3: e.target.value,
      }));
    }
    if (e.target.id === "mentalHealthIssues1") {
      setMentalHealthIssues((prevState) => ({
        ...prevState,
        T1: e.target.value,
      }));
    }
    if (e.target.id === "mentalHealthIssues2") {
      setMentalHealthIssues((prevState) => ({
        ...prevState,
        T2: e.target.value,
      }));
    }
    if (e.target.id === "mentalHealthIssues3") {
      setMentalHealthIssues((prevState) => ({
        ...prevState,
        T3: e.target.value,
      }));
    }
  };


  const handleInputChange = (question, ans) => {
    setRiskFactorActivity((prevState) => ({
      ...prevState,
      [question]: ans,
    }));
  };


  const handleSubmit = async () => {
    setIsPostTestEnabled(true);
    try {
      let api = `${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

      let req_json = {
        "modulename": "module1",
        "testtype": "activity",
        "moduleData": {

          "Approximately how much our national population constitute Children and adolescents": childrenAdolence.Q1,
          "Overall how much children are in need of mental health services?": childrenAdolence.Q2,
          "Name some common issues faced by children and adolescents": [commonIssue.I1, commonIssue.I2, commonIssue.I3,],
          riskFactorsActivity,
          "What are the mental health challenges faced by an individual? Name any 3 main problems.": [mentalHealthChallenges.T1, mentalHealthChallenges.T2,
          mentalHealthChallenges.T3,],

          "How would you tackle mental health issues ? mention 3 main points": [mentalHealthIssues.T1, mentalHealthIssues.T2,
          mentalHealthIssues.T3,],
          "How easy  was it to go through course pages?": radiofeedback.Q1,
          "Was the module organized and structured properly?": radiofeedback.Q2,

          "Did the module increased your knowledge and skills in the subject matter?": radiofeedback.Q3,
          "How helpful did you find the module?": radiofeedback.Q4,
          "Rate your overall experience of the Module?": radiofeedback.Q5,

        },


      };
      const response = await apiPostRequest(api, req_json);
      if (response) {
      } else {
        toastr.error(response.data.message)

      }

    }
    catch {
      toastr.error("There is an internal error! Please logout and login.")
    }

  };



  const enablePostTest = async () => {
    calculateUsedTime();
    const endTime = localStorage.getItem("currentTime");

    try {
      let api =`${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

      let req_json = {
        "modulename": "module1",
        "testtype": "posttest",
        "moduleData": {
          "What is the definition of mental health?": postTest.q1,
          "Approximately what percentage of the national population constitutes children and adolescents?": postTest.q2,
          "According to the National Mental Health Survey, what is the prevalence of mental health issues in children and adolescents aged 13-17 years?": postTest.q3,
          "Which of the following is NOT a common mental health issue faced by children and adolescents?	": postTest.q4,
          "Which of the following is NOT considered a risk factor for the development of mental health issues in children?": postTest.q
        },
        "endTime": endTime
      }
      const response = await apiPostRequest(api, req_json);
      if (response.data.success === true) {
        navigate('/user-home/activity/Module2');
      } else {
        toastr.error(response.data.message)
      }


    }
    catch {
      toastr.error("There is an internal error! Please logout and login.")
    }
  };
  const handlePostTestResults = (event, questionNumber) => {
    setPostTest((prevState) => ({
      ...prevState,
      [questionNumber]: event.target.value,
    }));
  };

  const handlePreTestResults = (event, questionNumber) => {
    setPretest((prevState) => ({
      ...prevState,
      [questionNumber]: event.target.value,
    }));
  };

  const onModule1PreTestSubmit = async () => {
    setIsPreTestCompleted(true);
    let currentTime = localStorage.getItem("currentTime")
    try {


      let api = `${process.env.REACT_APP_BASE_URL}/ModuleFunction`;


      let req_json = {
        "modulename": "module1",
        "testtype": "pretest",
        "moduleDataPretest": {
          "What is the definition of mental health?": pretest.q1,

          "Approximately what percentage of the national population constitutes children and adolescents?": pretest.q2,

          "According to the National Mental Health Survey, what is the prevalence of mental health issues in children and adolescents aged 13-17 years?": pretest.q3,
          "Which of the following is NOT a common mental health issue faced by children and adolescents?	": pretest.q4,
          "Which of the following is NOT considered a risk factor for the development of mental health issues in children?": pretest.q5
        },
        "currentTime": currentTime
      };
      const response = await apiPostRequest(api, req_json);

    }
    catch {
      toastr.error("There is an internal error! Please logout and login.")
    }
  }
  return (
    <>
      <ModulesMain notDefault={true} />

      {!isPreTestCompleted && (
        <form>

          <PrePostTest
            testType={"PRE-TEST"}
            testDetails={post_test_data}
            selectedValue={pretest}
            onOptSelect={handlePreTestResults}
            onTestSubmit={onModule1PreTestSubmit}
            btnClass={`col-md-1 col-sm-3 p-2 fw-bold ${areAllFieldsFilled(
              pretest
            )}`}
            moduleNo={1}
          />
        </form>
      )}

      {!isPostTestEnabled && isPreTestCompleted && (
        <div className="text-start module4-main-secton py-4 px-5">

          <form >
            <h1 className="mb-3">
              MODULE 1: Mental Health Disorders in Adolescents
            </h1>
            <h4 className="mt-4 fw-bold">
              Can you spare a minute to think about your health?
            </h4>
            <a href="https://youtu.be/NyWahyiFk9c" target="blank">
              Source: Can you spare a minute to think about your health? CLICK
              HERE FOR VIDEO
            </a>
            <h4 className="mt-3 fw-bold">What is mental health?</h4>
            <p>
              Mental health is a state of mental well-being that enables people
              to cope with the stresses of life, realize their abilities, learn
              well and work well, and contribute to their community.
            </p>
            <h4 className="mt-3 fw-bold">
              Watch video on: What is mental health?
            </h4>
            <a href="https://youtu.be/1Bq4Zo-iHXM" target="blank">
              (Source: Its Ok Baat Karo www.ItsOkToTalk.in) CLICK HERE FOR VIDEO
            </a>
            <h4 className="mt-3 fw-bold"> Statistics: </h4>
            <ul>
              <li>
                Children and adolescents constitute Approximately 40% of our
                national population, which is approximately 300 million.
              </li>
              <li>
                The recent National Mental Health Survey reported a prevalence
                of 7.3% in children and adolescents aged 13-17 years. Overall
                8-13% of children are in need of mental health services.
              </li>
            </ul>
            <h4 className="mt-3 fw-bold">  Common issues</h4>
            <ul>
              <li>Stress</li>
              <li>Anxiety</li>
              <li>Depression</li>
              <li>Anger</li>
              <li>Gaming</li>
              <li>Disorder etc</li>
            </ul>
            <h4 className="mt-3 fw-bold">
              Risk factors for the development of mental health issues
              in children:
            </h4>
            <CustomTable
              columns={[
                { id: "q", label: "Biological Factors" },
                {
                  id: "q1",
                  label: "Child characteristics and related factors",
                },
                { id: "q2", label: "Environmental factors" },
                { id: "q3", label: "Other factors" },
              ]}
              rows={[
                {
                  "Biological Factors":
                    "1. Family history of psychiatric and developmental disorders",
                  "Child characteristics and related factors":
                    "Age,Intelligence Thinking and coping styles",
                  "Environmental factors":
                    "Family environment, Parenting style, Neighborhood, School, Peeers, High parental expectations",
                  "Other factors":
                    "Poverty natural disasters, Migration war and violence overcrowing",
                },
              ]}
            />
            <h4 className="mt-3 fw-bold">

              <br /> Activity 1: Recall:
            </h4>
            {childrenAdolenceData.map((data, index) => (
              <>
                <h5 style={{ fontFamily: "callibri", width: "100%" }}>
                  {data.title}
                </h5>
                <RadioOptions
                  selectedValue={data.selectedValue}
                  name={data.name}
                  data={data.radioLabels.labels}
                  onChange={handleAdolescentRadioChange}
                  qNo={data.qNo}
                />
              </>
            ))}
            <h5 className="mt-3">
              Activity 2: Name some common issues faced by children and
              adolescents:
            </h5>
            <div className="mt-4 ">
              <InputField
                type="text"
                id="commonissue1"
                value={commonIssue.I1}
                onChange={commonIssueTrack}
              />
            </div>
            <div className="mt-4 ">
              <InputField
                type="text"
                id="commonissue2"
                value={commonIssue.I2}
                onChange={commonIssueTrack}
              />
            </div>
            <div className="mt-4 ">
              <InputField
                type="text"
                id="commonissue3"
                value={commonIssue.I3}
                onChange={commonIssueTrack}
              />
            </div>
            <h5 className="mt-3">
              Activity 3: Identify the type of risk factor:
            </h5>
            <CustomTable
              columns={[
                { id: "1", label: "No" },
                { id: "2", label: "Option" },
                { id: "3", label: "Answer" },
              ]}
              rows={[
                {
                  "No:": 1,
                  Option: "Sex and Age",
                  Answer: (
                    <input
                      type="text"
                      value={riskFactorsActivity["Sex and Age"]}
                      onChange={(e) =>
                        handleInputChange("Sex and Age", e.target.value)
                      }
                    />
                  ),
                },
                {
                  "No:": 2,
                  Option:
                    "Family history of psychiatric and developmental disorders",
                  Answer: (
                    <input
                      type="text"
                      value={
                        riskFactorsActivity[
                        "Family history of psychiatric and developmental disorders"
                        ]
                      }
                      onChange={(e) =>
                        handleInputChange(
                          "Family history of psychiatric and developmental disorders",
                          e.target.value
                        )
                      }
                    />
                  ),
                },
                {
                  "No:": 3,
                  Option:
                    "Poverty, natural disasters, migration, war and violence ",
                  Answer: (
                    <input
                      type="text"
                      value={
                        riskFactorsActivity[
                        "Poverty, natural disasters, migration, war and violence"
                        ]
                      }
                      onChange={(e) =>
                        handleInputChange(
                          "Poverty, natural disasters, migration, war and violence",
                          e.target.value
                        )
                      }
                    />
                  ),
                },
                {
                  "No:": 4,
                  Option:
                    "Neighborhood, school, peers, high parental expectations",
                  Answer: (
                    <input
                      type="text"
                      value={
                        riskFactorsActivity[
                        "Neighborhood, school, peers, high parental expectations"
                        ]
                      }
                      onChange={(e) =>
                        handleInputChange(
                          "Neighborhood, school, peers, high parental expectations",
                          e.target.value
                        )
                      }
                    />
                  ),
                },
              ]}
              className={"risk-factor mt-3 mb-3"}
            />
            <h4 className="mt-3 fw-bold"> Video: its ok not to be okay</h4>
            <a href="https://youtu.be/bkXOZgt7rxM" target="blank">
              Department of Mental health education1
            </a>{" "}
            <br />
            <a href="https://www.youtube.com/@departmentofmentalhealthed499" target="blank">
              Department of Mental health education2
            </a>
            <h4 className="mt-3 fw-bold">
              Explore(links for further exploration)
            </h4>
            <p
              style={{ fontFamily: "callibri" }}
              className="col-md-12 linksColor"
            >
              1.{" "}
              <a href="https://www.who.int/news-room/fact-sheets/detail/adolescent-mental-health" target="blank">
                Causes of Mental health disorders: WHO link
              </a>
            </p>
            <p
              style={{ fontFamily: "callibri" }}
              className="col-md-12 linksColor"
            >
              2.{" "}
              <a href="https://www.who.int/news-room/fact-sheets/detail/adolescent-mental-health" target="blank">
                Types of Mental health disorders: WHO link
              </a>
            </p>
            <h4 className="mt-3 fw-bold"> Assignment:</h4>
            <h5 className="mt-3">
              What are the mental health challenges faced by an individual? Name
              any 3 main problems.
            </h5>
            <div className="mt-4 ">
              <InputField
                type="text"
                id="mentalhealthchallenges1"
                value={mentalHealthChallenges.T1}
                onChange={commonIssueTrack}
              />
            </div>
            <div className="mt-4 ">
              <InputField
                type="text"
                id="mentalhealthchallenges2"
                value={mentalHealthChallenges.T2}
                onChange={commonIssueTrack}
              />
            </div>
            <div className="mt-4 ">
              <InputField
                type="text"
                id="mentalhealthchallenges3"
                value={mentalHealthChallenges.T3}
                onChange={commonIssueTrack}
              />
            </div>
            <h5 className="mt-3">
              How would you tackle mental health issues ? mention 3 main points
            </h5>
            <div className="mt-4 ">
              <InputField
                type="text"
                id="mentalHealthIssues1"
                value={mentalHealthIssues.T1}
                onChange={commonIssueTrack}
              />
            </div>
            <div className="mt-4 ">
              <InputField
                type="text"
                id="mentalHealthIssues2"
                value={mentalHealthIssues.T2}
                onChange={commonIssueTrack}
              />
            </div>
            <div className="mt-4 ">
              <InputField
                type="text"
                id="mentalHealthIssues3"
                value={mentalHealthIssues.T3}
                onChange={commonIssueTrack}
              />
            </div>
            <h4 className="mt-3 mb-3 fw-bold"> Feedback</h4>
            {radioValues.map((data, index) => (
              <>
                <h5
                  style={{
                    fontFamily: "callibri",
                    width: "100%",
                    marginTop: "3px",
                  }}
                >
                  {data.feedback_title}
                </h5>
                <RadioOptions
                  selectedValue={data.selectedValue}
                  name={data.name}
                  data={data.radioLabels.labels}
                  onChange={handleAdolescentRadioChange}
                  qNo={data.qNo}
                />
              </>
            ))}
            <Button
              style={{
                backgroundColor: "rgb(255, 135, 118)",
                color: "white",
                border: "none",
                marginTop: "15px",
                padding: "9px 22px 9px 22px",
                width: "fit-content",
              }}
              id="submit"
              text="Submit"
              onClick={handleSubmit}
            />
          </form>
        </div>
      )}

      {isPostTestEnabled && (
        <>
          <PrePostTest
            testType={"POST-TEST"}
            testDetails={post_test_data}
            selectedValue={postTest}
            onOptSelect={handlePostTestResults}
            onTestSubmit={enablePostTest}
            btnClass={`col-md-1 col-sm-3 p-2 fw-bold ${areAllFieldsFilled(
              postTest
            )}`}
            moduleNo={1}
          />

        </>
      )}
      {open
        ?
        <Dialog handleClose={handleClose} open={open} title={'Dear Students,'} content={'Once you have completed the test you cannot re-visit to modules!'} />
        :
        (<></>)
      }
    </>
  );
}
