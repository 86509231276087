import React, {useState} from "react";
import Layout from "../layout/Layout";
import ModuleHeader from "../module-main/modules-header/ModuleHeader";
import References from "./references/References";
import Video from "./videos/Video";
import "./AdditionalResources.css";


export default function AdditionalResources() {
    const [selectedSrc, setSelectedSrc] = useState("video")
    const onTabChange = (selectedTab) => {
        setSelectedSrc(selectedTab)
    }


    return (
        <>
            <Layout />
            <ModuleHeader />

            <div className="col-md-12 p-0 m-0 add-resrc-main-section">
                <div className="add-resrc-main-subsection col-md-12 mx-auto py-4 px-5 ">
                    <h3 className="fw-bold text-start mb-4 mt-2">Additional resources to explore</h3>
                    <h5 className="text-start mb-2 mt-2">Select sources:</h5>
                    <div className="col-md-3 m-0 p-0 row tabs-section">
                        <h5 className={selectedSrc == "ref" ? "col p-2 fw-normal inactive-tab": "col p-2 fw-normal active-tab"} onClick={(e)=>onTabChange('video')}>Videos</h5>
                        <h5 className={selectedSrc == "ref" ? "col p-2 fw-normal active-tab": "col p-2 fw-normal inactive-tab"} onClick={(e)=>onTabChange('ref')}>References</h5>
                    </div>
                    {
                        selectedSrc == "ref" ? (
                            <References/>
                        ) :
                        (
                            <Video/>
                        )
                    }

                </div></div>
        </>
    )
}