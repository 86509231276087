import React from "react";

import "./References.css";

export default function References() {

    const referencesDetailss = [
        {
            id: 1,
            data: {
                title: "UNICEF",
                desc: "",
                links: ["https://www.unicef-irc.org/research/mental-health/"]
            }
        },
        {
            id: 2,
            data: {
                title: "Mental HealtH Matters",
                desc: "",
                links: ["https://www.un.org/esa/socdev/documents/youth/youth-mental-health.pdf"]
            }
        },
        {
            id: 3,
            data: {
                title: "NIMHANS",
                desc: "",
                links: ["https://nimhans.ac.in/"]
            }
        },
        {
            id: 4,
            data: {
                title: "NGOs and other Organizations",
                desc: "",
                links: ["https://sangath.in/",
                    "https://sethu.in/",
                    "https://www.mindsfoundation.org/",
                    "https://manas.org.in/",
                    "https://www.whiteswanfoundation.org/life-stages/adolescence",
                    "https://www.mind.org.uk/information-support/types-of-mental-health-problems/mental-health-problems-introduction/about-mental-health-problems/"
                ]
            }
        },
        {
            id: 5,
            data: {
                title: "Teacher's Manual–Life Skills for Class-VIII (CBSE)",
                desc: "(Download) Ebook: Life Skills - Teacher’s Manual Class-VIII | CBSE EXAM PORTAL : CBSE, ICSE, NIOS, CTET Students Community [Internet]. [cited 2023 Jul 20].",
                links: ["https://cbseportal.com/ebook/life-skills-teachers-manual-class-8"]
            }
        },
        {
            id: 6,
            data: {
                title: "Empowering Adolescents using Life Skills for Psychosocial Competence Life Skill Educators’ Manual LEVEL I",
                desc: "NIMHANS Life Skill Educators’ Manual – NIMHANS [Internet]. [cited 2023 Jul 20].",
                links: [" https://nimhans.ac.in/life-skill-educators-manual/"]
            }
        },
        {
            id: 7,
            data: {
                title: "Empowering Adolescents using Life Skills for Psychosocial Competence Life Skill Educators’ Manual LEVEL II",
                desc: "NIMHANS Life Skill Educators’ Manual – NIMHANS [Internet]. [cited 2023 Jul 20].",
                links: ["https://nimhans.ac.in/life-skill-educators-manual/"]
            }
        },
        {
            id: 8,
            data: {
                title: "Empowering Adolescents using Life Skills for Psychosocial Competence Life Skill Educators’ Manual LEVEL III",
                desc: "NIMHANS Life Skill Educators’ Manual – NIMHANS [Internet]. [cited 2023 Jul 20].",
                links: ["https://nimhans.ac.in/life-skill-educators-manual/"]
            }
        },
        {
            id: 9,
            data: {
                title: "Mental Health and Wellbeing A Perspective (CBSE)",
                desc: "CBSE_MH_Manual.pdf [Internet]. [cited 2023 Jul 20]. ",
                links: ["https://manodarpan.education.gov.in/assets/img/pdf/CBSE_MH_Manual.pdf"]
            }
        },
        {
            id: 10,
            data: {
                title: "Child And Adolescent Mental Health A Manual for Medical Officers (NIMHANS)",
                desc: "Child-and-Adolescent-Mental-Health-A-Manual-for-Medical-officers.pdf [Internet]. [cited 2023 Jul 20].",
                links: ["https://nimhans.ac.in/wp-content/uploads/2020/12/Child-and-Adolescent-Mental-Health-A-Manual-for-Medical-officers.pdf"]
            }
        }
    ]

    const tableData = [
        {
            "NO": 1,
            "TOPIC": "What is mental health?",
            "VIDEOS": [
                {
                    "title": "Can you spare a minute to think about your health?",
                    "link": "https://youtu.be/1Bq4Zo-iHXM",
                    "source": "It's Ok Baat Karo (1.52 min)"
                },
                {
                    "title": "Can you spare a minute to think about your health?",
                    "link": "https://youtu.be/NyWahyiFk9c",
                    "source": "(2.50 min)"
                },
                {
                    "title": "Mental health Disorders in Adolescents.",
                    "link": "https://youtu.be/bkXOZgt7rxM",
                    "source": "It’s Okay  Not to Be Okay: Department of Mental health education (4.32 min)"
                }
            ]
        },
        {
            "NO": 2,
            "TOPIC": "Stress",
            "VIDEOS": [
                {
                    "title": "How stress affects your brain - Madhumita Murgia",
                    "link": "https://www.youtube.com/watch?v=WuyPuH9ojCE",
                    "source": "(source :  TED Ed )"
                },
                {
                    "title": "How stress affects your body - Sharon Horesh Bergquist",
                    "link": "https://www.youtube.com/watch?v=v-t1Z5-oPtU",
                    "source": "( source: TED Ed)(4.42 min)"
                },
                {
                    "title": "How Stress Affects Your Body and Mind",
                    "link": "https://www.youtube.com/watch?v=Ba_A43kzNBw",
                    "source": "(source: braive.com) (2.25 min)"
                }
            ]
        },
        {
            "NO": 3,
            "TOPIC": "Academic stress",
            "VIDEOS": [
                {
                    "title": "A video on stress management through Yoga practice by Dr. Hemanth Bhargav.",
                    "link": "https://youtu.be/Njr3MLLY5VA",
                    "source": "source :(NIMHANS) (54.42mins)"
                },
                {
                    "title": "A Demonstration of yoga for Stress Management by Dr. Hemanth Bhargav.",
                    "link": "https://youtu.be/kQgZMZGprPE",
                    "source": "source :(NIMHANS) (1.52 min)"
                }
            ]
        },
        {
            "NO": 4,
            "TOPIC": "Depression and anxiety",
            "VIDEOS": [
                {
                    "title": "What is depression?",
                    "link": "https://www.youtube.com/watch?v=z-IR48Mb3W0",
                    "source": "(source : TED ED) ( 4.28 MIN)"
                },
                {
                    "title": "Handling Emotional Stress and Anxiety",
                    "link": "https://youtu.be/SA-sefgdRq4",
                    "source": "( source: Department of Mental health education https://www.youtube.com/@departmentofmentalhealthed499) (10.39 min)"
                },
                {
                    "title": "Handling Stress and Anxiety",
                    "link": "https://youtu.be/XWjmiYwlw8k",
                    "source": "(source: Department of Mental health education https://www.youtube.com/@departmentofmentalhealthed499) ( 1.26 min)"
                }
            ]
        },
        {
            "NO": 5,
            "TOPIC": "Screen use/social media and addiction",
            "VIDEOS": [
                {
                    "title": "What causes insomnia? - Dan Kwartler",
                    "link": "https://www.youtube.com/watch?v=j5Sl8LyI7k8",
                    "source": "( source: TED Ed) (5.11 min)"
                },
                {
                    "title": "Is binge-watching bad for you?",
                    "link": "https://www.youtube.com/watch?v=KJRzgl0FuMA",
                    "source": "( source: https://www.youtube.com/@AsapSCIENCE) ( 2.42 min)"
                }
            ]
        },
        {
            "NO": 6,
            "TOPIC": "Anger management",
            "VIDEOS": [
                {
                    "title": "This Video Will Make You Angry",
                    "link": "https://www.youtube.com/watch?v=rE3j_RHkqJc",
                    "source": "( source GCP GREY: https://www.youtube.com/@CGPGrey ) ( 6.16 MIN) "
                },
                {
                    "title": "Anger Management (Student video) by KLE Tech team, BVB, Hubbali.",
                    "link": "",
                    "source": ""
                }
            ]
        },
        {
            "NO": 7,
            "TOPIC": "LSE",
            "VIDEOS": [
                {
                    "title": "An Exercise in Self-Compassion",
                    "link": "https://www.youtube.com/watch?v=-kfUE41-JFw&t=4s",
                    "source": "( source :  self compassion , https://www.youtube.com/@theschooloflifetv) (4.42 min)"
                },
                {
                    "title": "3 tips to boost your confidence - TED-Ed",
                    "link": "https://www.youtube.com/watch?v=l_NYrWqUR40&t=49s",
                    "source": "( source: TED Ed) ( 4.16 min)"
                }
            ]
        },
        {
            "NO": 8,
            "TOPIC": "Relationships: Parent teacher support",
            "VIDEOS": [
                {
                    "title": "1. How to build a fictional world?",
                    "link": "https://ted.com/talks/kate_messner_how_to_build_a_fictional_world",
                    "source": "( source : TED Ed ) (5.04 min)"
                },
                {
                    "title": "5 Exercises to Help You Build More of Empathy",
                    "link": "https://blog.ed.ted.com/2021/04/12/5-exercises-to-help-you-build-more-empathy/",
                    "source": "( source: Ted Ed)"
                },
                {
                    "title": "Managing Family & Friends",
                    "link": "https://youtu.be/KVfB7c0LIwE",
                    "source": "( source: source: Department of Mental health education https://www.youtube.com/@departmentofmentalhealthed499) (5.27 min)"
                }
            ]
        },
        {
            "NO": 9,
            "TOPIC": "Peer pressure",
            "VIDEOS": [
                {
                    "title": "How do cigarettes affect the body? - Krishna Sudhir",
                    "link": "https://www.youtube.com/watch?v=Y18Vz51Nkos",
                    "source": "(source: TED Ed) (5.20 min)"
                }
            ]
        }
    ]


    return (
        <>

            <div className="col references-main-section mt-4 text-start">
                <ol>
                    {referencesDetailss.map((reference) => (
                        <li key={reference.id} className="mt-3">
                            <h6 className="fw-bold">{reference.data.title}</h6>
                            {reference.data.desc != '' && (
                                <p className="m-0 ">{reference.data.desc}</p>
                            )}
                            <ul>
                                {reference.data.links.map((link, index) => (
                                    <li key={index}>
                                        <a href={link}
                                            className="add-resrc-links"
                                            target="_blank" rel="noopener noreferrer">
                                            {link}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ol>

                <h5><b>Video Links:</b></h5>
                <table className="col-md-12 mt-3">
                    <thead>
                        <tr className="text-center">
                            <th className="p-2">NO.</th>
                            <th>TOPIC</th>
                            <th>VIDEOS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((item) => (
                            <tr key={item.NO}>
                                <td className="text-center">{item.NO}</td>
                                <td className="text-center">{item.TOPIC}</td>
                                <td>
                                    <ul className="m-0 pt-1">
                                        {item.VIDEOS.map((video, index) => (
                                            <li key={index} className="mb-2">
                                                 {video.title}<br></br>
                                                <a href={video.link} target="_blank" className="add-resrc-links" rel="noopener noreferrer">
                                                    {video.link}
                                                </a><br></br>
                                                {video.source}
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>


        </>
    )
}