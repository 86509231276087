import React from "react";
import doctorImage from "../../images/LoginDoctorImage.jpg";
import bannerImg1 from "../../images/bannerImg1.jpg";


export default function About() {
    return (
        <>
            About

            <div class="container bg-dark mt-5 col-md-12 p-0">
                <div id="myCarousel" class="carousel slide" data-ride="carousel">

                    <ul class="carousel-indicators">
                        <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                        <li data-target="#myCarousel" data-slide-to="1"></li>
                        <li data-target="#myCarousel" data-slide-to="2"></li>
                    </ul>


                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src={bannerImg1} alt="Image 1" />
                            <div class="col-sm-10 col-lg-8 carousel-caption d-none text-left d-md-block p-0">
                                <h5 class="text-primary text-uppercase mb-3 animated slideInDown"> Course</h5>
                                <h1 class="display-3 text-white animated slideInDown">What Is Mental Health</h1>
                                <p class="fs-5 text-white mb-4 pb-2">Mental health includes our emotional, psychological, and social well-being. It affects how we think, feel, and act. It also helps determine how we handle stress, relate to others, and make choices. Mental health is important at every stage of life, from childhood and adolescence through adulthood.</p>
                                <a href="" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Read More</a>
                                
                            </div>
                        </div>

                    </div>

                    <a class="carousel-control-prev" href="#myCarousel" data-slide="prev">
                        <span class="carousel-control-prev-icon"></span>
                    </a>
                    <a class="carousel-control-next" href="#myCarousel" data-slide="next">
                        <span class="carousel-control-next-icon"></span>
                    </a>
                </div>
            </div>
        </>
    )
}