import React, { useEffect, useState } from "react";
import ModulesMain from "../ModulesMain";
import PrePostTest from "./../pre-post-test/PrePostTest";
import { Input } from "@mui/material";
import RadioOptions from "../../../components/radiogroup/RadioOptions";
import Feedback from "../feedback/Feedback";
import { areAllFieldsFilled } from "../../../utils/Tools";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";
import { apiPostRequest } from "../../../utils/Network";
import "./Module.css";
import calculateUsedTime from "../../../utils/Tools";

export default function Module8() {
  const navigate = useNavigate();
  toastr.options = {
    closeButton: true,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    timeOut: 3000,
  };
  const [preTestQnAnswers, setPreTestQnAnswers] = useState({
    q1: {
      ques: "What is the importance of being able to be yourself in relationships?",
      data: [
        {
          value: "It allows you to throw tantrums and be rude to others",
          label: "It allows you to throw tantrums and be rude to others",
        },
        {
          value: "It makes relationships unsatisfying",
          label: "It makes relationships unsatisfying",
        },
        {
          value: "It improves mental health and self-esteem",
          label: "It improves mental health and self-esteem",
        },
        {
          value: "It creates interdependence and influences each other",
          label: "It creates interdependence and influences each other",
        },
      ],
    },
    q2: {
      ques: "True or False: Good relationships require no work and can be easily maintained",
      data: [
        {
          value: "True",
          label: "True",
        },
        {
          value: "False",
          label: "False",
        },
      ],
    },
    q3: {
      ques: "What is the key to improving relationships according to the text?",
      data: [
        {
          value: "Changing other people",
          label: "Changing other people",
        },
        {
          value: "Knowing oneself and others",
          label: "Knowing oneself and others",
        },
        {
          value: "Holding resentments towards others",
          label: "Holding resentments towards others",
        },
        {
          value: "Being unsatisfied in fulfilling roles",
          label: "Being unsatisfied in fulfilling roles",
        },
      ],
    },
    q4: {
      ques: "How can you maintain a good relationship?",
      data: [
        {
          value: "Complain frequently to release stress",
          label: "Complain frequently to release stress",
        },
        {
          value: "Force your likings and dislikings on others",
          label: "Force your likings and dislikings on others",
        },
        {
          value: "Treat others with kindness and have an attitude of gratitude",
          label: "Treat others with kindness and have an attitude of gratitude",
        },
        {
          value: "Keep your problems and difficulties within the relationship",
          label: "Keep your problems and difficulties within the relationship",
        },
      ],
    },
    q5: {
      ques: "What is an important aspect of communication in relationships?",
      data: [
        {
          value: "Ignoring the feelings of others",
          label: "Ignoring the feelings of others",
        },
        {
          value: "Avoiding seeking help from parents or teachers",
          label: "Avoiding seeking help from parents or teachers",
        },
        {
          value: "Talking face-to-face with family and friends",
          label: "Talking face-to-face with family and friends",
        },
        {
          value: "Forgetting important dates in the lives of love",
          label: "Forgetting important dates in the lives of love",
        },
      ],
    },
  });
  const [preTestResults, setPreTestResults] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
  });
  const [activeStep, setActiveStep] = useState(1);
  const [feedback, setFeedback] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
  });
  const [postTestResults, setPostTestResults] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
  });

  const [moduleActivityData, setModuleActivityData] = useState([]);

  const [pageContent, setPageContent] = useState({
    page1: {
      title: " Introduction",
      subTitle:
        "Humans are social animals. It is essential to adapt and build relationships for our mental health, self-esteem, and ability to work is influenced greatly by our relationships. Your relationships will work best if you are able to be yourself. ",
      content: [
        "Relationships in which you can be yourself are likely to make you feel more comfortable and happier. This does not mean you can throw tantrums when you feel like it, nor be rude to people as you wish",
        "Relationships tend to be unsatisfying when one is fulfilling a role rather than being oneself",
        "To improve relationships one must first understand them. Your relationship with others is the key to your success or failure",
      ],
    },
    page2: {
      title: " What is relationship?",
      subTitle:
        "A relationship is a connection between individuals such as parent-child relationship. Relationships usually involve some level of interdependence and tend to influence each other. They share their thoughts and feelings with each other.",
      content: [
        "Good relationships requires lot of work and has to be maintained",
        "It is wrong to hold a view that the best way of improving our relationships is to change other people",
        "Knowing yourself will enable you to revel in other's success and achievements rather than resenting them",
        "Knowing the other persons you are intimately connected with is definitely worth it but it is not an easy task.This is the most important to improve your relationships",
      ],
    },
    page3: {
      title: "Activity 1: Read the paragraph and answer the questions",
      content: {
        story1: [
          `Rahul On his way back from school, met a stranger. And he said, "Oh,excuse me please".`,
          `The stranger said, "Please excuse me too; I wasn't watching for you”.`,
          `Both of us were very polite. After saying goodbye, we went on our way.`,
        ],
        story2: [
          `I reached home, threw my bag on the couch. My mother came in and said, "Pick up your bag and keep it on your study table". I ignored what she said.`,
          `I went to my younger sister watching television, snatched the television remote from her hand and pushed her aside. She fell down and started crying.`,
          `My mother came running inside, "What happened?"`,
          `While pointing towards me, my sister said, "He pushed me and snatched the remote from my hand".`,
          `I immediately replied, "I just took the remote and she is cooking up stories".`,
          `But my mother was adamant and said, "Say sorry to her, she is hurt".`,
          `"Oh it was all her fault, she watches so much of cartoons, she could have given me the remote when I asked her and you also favour her all the time", said I and left the room.`,
          `Later that day, while I was doing my homework, my sister stood beside me very still. When I turned, I nearly knocked her down. "Move out of my way", I said with a frown. My sister walked away, her little heart broken. I did not realize how harshly I have spoken.`,
          `While I was lying awake in bed trying to sleep, a small voice came to me and said, "While dealing with a stranger, you use common courtesy, but the people  you love, you seem to abuse. Look on the study table, you will find a card  there. That card was made by your baby sister. She drew and coloured it herself. Your sister stood quietly not to spoil the surprise, and you never saw the tears in her eyes." `,
          `By this time, I felt very small, and now my tears began to fall.I quietly went and knelt by her bed and said, "Wake up, little sister, wake up". `,
          `"Is this the card you made for me?”`,
          `My sister smiled, "I thought I should say sorry and tell you that you are the best brother. I knew you would like it and you can watch television whenever you  want to.”`,
          `I said, "Sister, I am sorry for the way I acted today. I should not have pushed you that way.”`,
          `My sister said, "Oh brother, that's okay. I love you anyway.”`,
          `I said, "Sister, I love you too, and I do like this card, especially made by you”.`,
        ],
        activity: {
          1: [
            {
              qn: "How was Rahul’s behaviour with the stranger ?",
              ans: "",
            },
            {
              qn: "What did rahul’s mother asked him to do ?",
              ans: "",
            },
            {
              qn: "Was Rahul aware how harshly he spoke with his sister ?",
              ans: "",
            },
            {
              qn: "How did rahul  solved the problem with his sister ?",
              ans: "",
            },
          ],
        },
      },
    },
    page4: {
      title: " How to improve your relationships?",
      content: [
        "Know oneself, friends and teachers",
        "Take responsibility",
        "Treat others the way you want to be treated",
        "Gratitude instead of complaints, help others too",
        "Being tolerant, avoid groupism, bullying others",
        "Change yourself rather than changing others",
        "Loving, living and sharing knowledge",
        "Face the difficulties and problems within your relationships",
        "Nurture your relationships with patience",
      ],
    },
    page5: {
      title:
        " Best relationships are based on living, loving and sharing",
      content: [
        "Living means letting others live as they choose. Do not try to force your likings and disliking’s over them",
        "Loving means unconditional love. Loving people for who they are.",
        "Sharing creates harmony and balance in a relationship. Solve your problems accordingly and in peaceful manner",
      ],
    },
    page6: {
      title:
        " Activity : How does living, loving and sharing helps you in your day to day life?",
      answer: "",
    },
    page7: {
      title: " Communication in Relationships",
      content: [
        "Talk openly about your feelings to one another to sort out your problems",
        "Seek help whenever needed from parents or teachers",
        "Spend quality time with the family and friends",
        "Never be too busy to call or talk to loved ones",
        "Talk face-to-face with family and friends",
        "Be a good listener by paying attention to details",
        "Be available whenever a friend or a family member is in need",
        "Remember the important dates in the lives of your loved ones and wish them",
        'Whenever need be, never feel shy to say,"I am sorry"',
      ],
    },
    page8: {
      title: " Activity 2",
      content: {
        activity: {
          a: {
            title:
              "a] What are the difficulties you face while communicating with friends and classmates ?  ( write 5 points )",
            points: ["", "", "", "", ""],
          },
          b: {
            title:
              "b] What are the difficulties you face while communicating with  parents and teachers ? ( write 5 points )",
            points: ["", "", "", "", ""],
          },
        },
      },
    },
    page9: {
      title: "Activity: 3. Recollect",
      subTitle: "Choose the right answer",
      content: {
        activity: {
          3: [
            {
              title: "You improve relationships by",
              options: [
                { label: "By knowing oneself", value: "By knowing oneself" },
                {
                  label: "Not listening to others",
                  value: "Not listening to others",
                },
                { label: "By hating all", value: "By hating all" },
              ],
              selectedValue: "",
            },
            {
              title: "How to maintain good relationship?",
              options: [
                { label: "Being Angry", value: "Being Angry" },
                {
                  label: "Trying to force one’s preferences on others",
                  value: "Trying to force one’s preferences on others",
                },
                { label: "Being Loving", value: "Being Loving" },
              ],
              selectedValue: "",
            },
            {
              title: "Communication in relationships means",
              options: [
                {
                  label: "Not talking openly about one’s feelings",
                  value: "Not talking openly about one’s feelings",
                },
                {
                  label: "Spending quality time with  loved ones",
                  value: "Spending quality time with  loved ones",
                },
                { label: "Being too busy", value: "Being too busy" },
              ],
              selectedValue: "",
            },
          ],
        },
        assignment: {
          title:
            "Assignment :  Make a collage on : friends ,parents and teacher support",
          note: [
            "Pick your favorite memories with your friends",
            "Recollect the memories where you were inspired by a teacher",
          ],
          subNote: "Note : Be creative and show your talent",
        },
      },
    },
  });

  const [module8, setModule8] = useState([]);

  const apiCall = async () => {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}/eachmodulestatus`;
      const req_json = { "modulename": "module8", "modulenumber": 8 }
      const response = await apiPostRequest(api, req_json);
      if (response.data.success == true) {
        const dataToPass = response.data.message["modulestatus"];
        if (dataToPass.pretest === 'completed') {
          setActiveStep(2);
        }
        if (dataToPass.pretest === 'completed' && dataToPass.postTest === 'in-progress') {
          setActiveStep(3);
        }
        if (dataToPass.pretest === 'completed' && dataToPass.activity === 'completed') {
          setActiveStep(3)
        }
        if (dataToPass.pretest === "completed" && dataToPass.activity === "completed" && dataToPass.posttest === "completed") {
          navigate("/user-home/activity");
        }
      } else {
        toastr.error(response);
      }
    }
    catch {
      toastr.error("There is an internal error! Please logout and login.")
    }
  }

  useEffect(() => {
    const modulestatus = JSON.parse(localStorage.getItem("all_module_status"))
    if (modulestatus[6].status === 'completed') {
      apiCall();
    } else {
      navigate('/user-home/activity')
    }
  }, []);

  const handlePreTestResults = (event, questionNumber) => {
    setPreTestResults((prevState) => ({
      ...prevState,
      [questionNumber]: event.target.value,
    }));
  };

  const onModule8PreTestSubmit = async () => {
    setActiveStep(2);
    const currentTime = localStorage.getItem("currentTime");
    try {
      let api = `${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

      let req_json = {
        "modulename": "module8",
        "testtype": "pretest",
        "moduleDataPretest": {
          "What is the importance of being able to be yourself in relationships?": preTestResults.q1,
          "True or False: Good relationships require no work and can be easily maintained": preTestResults.q2,
          "What is the key to improving relationships according to the text?": preTestResults.q3,
          "How can you maintain a good relationship?": preTestResults.q4,
          "What is an important aspect of communication in relationships?": preTestResults.q5
        },
        "currentTime": currentTime
      }
      const response = await apiPostRequest(api, req_json);
    }
    catch {
      toastr.error("There is an internal error! Please logout and login.")
    }
  };

  const onActivityAnsChange = (event) => {
    const copyOfPageContent = { ...pageContent };
    const copyOfContent = [...pageContent["page3"].content.activity[1]];
    copyOfContent[event.target.id].ans = event.target.value;
    const activityAnsChange = {
      ...moduleActivityData,

      [copyOfContent[0].qn]: copyOfContent[0].ans,
      [copyOfContent[1].qn]: copyOfContent[1].ans,
      [copyOfContent[2].qn]: copyOfContent[2].ans,
      [copyOfContent[3].qn]: copyOfContent[3].ans

    }
    setModuleActivityData(activityAnsChange);

    pageContent["page3"].content.activity[1] = copyOfContent;
    setPageContent(copyOfPageContent);
  };


  const onPage6ActivityChange = (event) => {
    const copyOfPageContent = { ...pageContent };
    const copyOfContent = { ...pageContent["page6"] };
    copyOfContent.answer = event.target.value;
    pageContent["page6"] = copyOfContent;
    const page6ActivityContent = {
      ...moduleActivityData,

      [copyOfContent.title]: copyOfContent.answer,

    }

    setModuleActivityData(page6ActivityContent);
    setPageContent(copyOfPageContent);
  };

  const onPage8ActivityAChange = (event) => {

    const copyOfPageContent = { ...pageContent };
    const copyOfContent = [
      ...copyOfPageContent.page8.content.activity.a.points,
    ];
    copyOfContent[event.target.id] = event.target.value;
    copyOfPageContent.page8.content.activity.a.points = copyOfContent;
    const activityA = {
      ...moduleActivityData,
      "a] What are the difficulties you face while communicating with friends and classmates ? ( write 5 points )": copyOfContent
    }
    setModuleActivityData(activityA);
    setPageContent(copyOfPageContent);
  };

  const onPage8BActivityAChange = (event) => {
    const copyOfPageContent = { ...pageContent };
    const copyOfContent = [
      ...copyOfPageContent.page8.content.activity.b.points,
    ];
    copyOfContent[event.target.id] = event.target.value;
    copyOfPageContent.page8.content.activity.b.points = copyOfContent;
    const activityB = {
      ...moduleActivityData,

      "b] What are the difficulties you face while communicating with parents and teachers ? ( write 5 points )": copyOfContent
    }
    setModuleActivityData(activityB);
    setPageContent(copyOfPageContent);
  };

  const onPage9ActivityChange = (event, itemIndex) => {
    const copyOfPageContent = { ...pageContent };
    const copyOfContent = [...copyOfPageContent.page9.content.activity["3"]];
    copyOfContent[itemIndex - 1].selectedValue = event.target.value;
    copyOfPageContent.page9.content.activity["3"] = copyOfContent;
    const recollectData = {
      ...moduleActivityData,
      [copyOfPageContent.page9.content.activity["3"][0].title]: copyOfPageContent.page9.content.activity["3"][0].selectedValue,
      [copyOfPageContent.page9.content.activity["3"][1].title]: copyOfPageContent.page9.content.activity["3"][1].selectedValue,
      [copyOfPageContent.page9.content.activity["3"][2].title]: copyOfPageContent.page9.content.activity["3"][2].selectedValue,
    };
    setModuleActivityData(recollectData);
    setPageContent(copyOfPageContent);
  };

  const onModuleFeedbackChange = (event, questionNumber) => {
    setFeedback((prevState) => ({
      ...prevState,
      [questionNumber]: event.target.value,
    }));
  };

  const onModule8Submit = async () => {

    setActiveStep(3);
    try {
      let api = `${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

      let req_json = {
        "modulename": "module8",
        "testtype": "activity",
        "moduleData": {
          moduleActivityData,
          "1: How easy was it to go through course pages?": feedback.q1,
          "2: Was the module organized and structured properly?": feedback.q2,
          "3: Did the module increased your knowledge and skills in the subject matter?": feedback.q3,
          "4: How helpful did you find the module?": feedback.q4,
          "5: Rate your overall experience of the Module": feedback.q5
        }
      }
      const response = await apiPostRequest(api, req_json);
    }
    catch {
      toastr.error("There is an internal error! Please logout and login.")
    }
  };

  const handlePostTestResults = (event, questionNumber) => {
    setPostTestResults((prevState) => ({
      ...prevState,
      [questionNumber]: event.target.value,
    }));
    setModule8([
      {
        "What is the importance of being able to be yourself in relationships?": preTestResults.q1,
        "True or False: Good relationships require no work and can be easily maintained": preTestResults.q2,
        "What is the key to improving relationships according to the text?": preTestResults.q3,
        "How can you maintain a good relationship?": preTestResults.q4,
        "What is an important aspect of communication in relationships?": preTestResults.q5
      },

      {
        "What is the importance of being able to be yourself in relationships?": postTestResults.q1,
        "True or False: Good relationships require no work and can be easily maintained": postTestResults.q2,
        "What is the key to improving relationships according to the text?": postTestResults.q3,
        "How can you maintain a good relationship?": postTestResults.q4,
        "What is an important aspect of communication in relationships?": postTestResults.q5
      }
    ]);
  };

  const onModule8PostTestSubmit = async () => {
    setActiveStep(1);
    calculateUsedTime();
    const endTime = localStorage.getItem("currentTime");
    try {
      let api = `${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

      let req_json = {
        "modulename": "module8",
        "testtype": "posttest",
        "moduleData": {
          "What is the importance of being able to be yourself in relationships?": postTestResults.q1,
          "True or False: Good relationships require no work and can be easily maintained": postTestResults.q2,
          "What is the key to improving relationships according to the text?": postTestResults.q3,
          "How can you maintain a good relationship?": postTestResults.q4,
          "What is an important aspect of communication in relationships?": postTestResults.q5
        },
        "endTime": endTime
      }
      const response = await apiPostRequest(api, req_json);
      if (response.data.success === true) {
        navigate('/user-home/activity/Module9');
      }
    }
    catch {
      toastr.error("There is an internal error! Please logout and login.")
    }

  };

  return (
    <>
      <ModulesMain notDefault={true} />
      {activeStep == 1 && (
        <PrePostTest
          testType={"PRE-TEST"}
          testDetails={preTestQnAnswers}
          selectedValue={preTestResults}
          onOptSelect={handlePreTestResults}
          onTestSubmit={onModule8PreTestSubmit}
          moduleNo={8}
          btnClass={`col-md-1 col-sm-3 p-2 fw-bold ${areAllFieldsFilled(
            preTestResults
          )}`}
        />
      )}
      {activeStep == 2 && (
        <div className="text-start module4-main-secton py-4 px-5">
          <h1 className="mb-3">
            MODULE 8: Relationships: Parent teacher support
          </h1>
          <h4>
            <b>{pageContent["page1"].title}</b>
          </h4>
          <p>{pageContent["page1"].subTitle}</p>
          <ul>
            {pageContent["page1"].content.map((item, itemIndex) => (
              <li key={itemIndex}>{item}</li>
            ))}
          </ul>
          <h4>
            <b>{pageContent["page2"].title}</b>
          </h4>
          <p>{pageContent["page2"].subTitle}</p>
          <ul>
            {pageContent["page2"].content.map((item, itemIndex) => (
              <li key={itemIndex}>{item}</li>
            ))}
          </ul>
          <h4>
            <b>{pageContent["page3"].title}</b>
          </h4>
          <div className="ms-3">
            <p>
              <b>The Importance Of Relationships: Family and Stranger story </b>
            </p>
            {pageContent["page3"].content.story1.map((item, itemIndex) => (
              <p key={itemIndex}>{item}</p>
            ))}
            <p>
              <b>
                But at home a different story is told, how we treat our loved
                ones, young and old.
              </b>
            </p>
            {pageContent["page3"].content.story2.map((item, itemIndex) => (
              <p key={itemIndex}>{item}</p>
            ))}
            {pageContent["page3"].content.activity[1].map((qn, qnIndex) => (
              <span>
                <p>
                  <b>{"Qs" + qnIndex + 1 + ". " + qn.qn} </b>
                </p>
                <p className="d-flex">
                  {" "}
                  {"Answer: "}{" "}
                  <Input
                    id={qnIndex}
                    className="col-md-11 px-2 mx-1"
                    value={qn.ans}
                    onChange={onActivityAnsChange}
                  />
                </p>
              </span>
            ))}
          </div>
          <h4>
            <b>{pageContent["page4"].title}</b>
          </h4>
          <ul>
            {pageContent["page4"].content.map((item, itemIndex) => (
              <li key={itemIndex}>{item}</li>
            ))}
          </ul>
          <h4>
            <b>{pageContent["page5"].title}</b>
          </h4>
          <ul>
            {pageContent["page5"].content.map((item, itemIndex) => (
              <li key={itemIndex}>{item}</li>
            ))}
          </ul>
          <h4>
            <b>{pageContent["page6"].title}</b>
          </h4>
          <textarea
            className="answereheretextaream8"
            id="page6Activity"
            name="page6Activity"
            rows="3"
            cols="100"
            placeholder="Answer here"
            onChange={onPage6ActivityChange}
          ></textarea>
          <h4 className="mt-3">
            <b>{pageContent["page7"].title}</b>
          </h4>
          <ul>
            {pageContent["page7"].content.map((item, itemIndex) => (
              <li key={itemIndex}>{item}</li>
            ))}
          </ul>
          <h4>
            <b>{pageContent["page8"].title}</b>
          </h4>
          <h5>{pageContent["page8"].content.activity.a.title}</h5>
          {pageContent["page8"].content.activity.a.points.map(
            (point, pointIndex) => (
              <span>
                <p className="d-flex">
                  {pointIndex + 1}:{" "}
                  <Input
                    id={pointIndex}
                    className="col-md-11 px-2 mx-1"
                    value={point}
                    onChange={onPage8ActivityAChange}
                  />
                </p>
              </span>
            )
          )}
          <h5>{pageContent["page8"].content.activity.b.title}</h5>
          {pageContent["page8"].content.activity.b.points.map(
            (point, pointIndex) => (
              <span>
                <p className="d-flex">
                  {pointIndex + 1}:{" "}
                  <Input
                    id={pointIndex}
                    className="col-md-11 px-2 mx-1"
                    value={point}
                    onChange={onPage8BActivityAChange}
                  />
                </p>
              </span>
            )
          )}
          <h4>
            <b>{pageContent["page9"].title}</b>
          </h4>
          <h5>{pageContent["page9"].subTitle}</h5>
          <ol>
            {pageContent["page9"].content.activity["3"].map(
              (item, itemIndex) => (
                <span key={itemIndex}>
                  <li>{item.title}</li>
                  <RadioOptions
                    selectedValue={item.selectedValue}
                    name={item.title}
                    onChange={onPage9ActivityChange}
                    data={item.options}
                    qNo={itemIndex + 1}
                  />
                </span>
              )
            )}
          </ol>
          <h5>{pageContent.page9.content.assignment.title}</h5>
          <p>
            <b>Note:</b>
          </p>
          <ul>
            {pageContent["page9"].content.assignment.note.map(
              (item, itemIndex) => (
                <li key={itemIndex}>
                  <b>{item}</b>
                </li>
              )
            )}
          </ul>
          <h4>
            <b>Feedback</b>
          </h4>
          <Feedback
            selectedValue={feedback}
            onOptSelect={onModuleFeedbackChange}
            onSubmit={onModule8Submit}
          />
        </div>
      )}
      {activeStep == 3 && (
        <PrePostTest
          testType={"POST-TEST"}
          testDetails={preTestQnAnswers}
          selectedValue={postTestResults}
          onOptSelect={handlePostTestResults}
          onTestSubmit={onModule8PostTestSubmit}
          moduleNo={8}
          btnClass={`col-md-1 col-sm-3 p-2 fw-bold ${areAllFieldsFilled(
            postTestResults
          )}`}
        />
      )}
    </>
  );
}
