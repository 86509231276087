import React, { useEffect, useState } from "react";
import { apiGetRequest, apiPostRequest } from "../../../utils/Network";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
} from "@mui/material";

export default function SuperAdminMainPage() {
  const [userDetails, setUserDetails] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAdminDetails();
  }, []);

  const fetchAdminDetails = async () => {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}/SuperAdminGetAdminRegistrations`;
      const response = await apiGetRequest(api);
      console.log("repo", response.data);
      if (response.data.success) {
        const rows = response.data.admin_registrations.map((admin) => {
          const row = {};
          Object.keys(admin).forEach((key) => {
            let value = admin[key];
            if (typeof value === "object" && value !== null && "$oid" in value) {
              value = value.$oid;
            }
            row[key] = value ?? ""; // Handle null or undefined values
          });
          return row;
        });

        const columns = Object.keys(rows[0]).map((key) => ({
          id: key,
          label: key
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase()),
          minWidth: 120,
        }));

        setColumns(columns);
        setUserDetails(rows);
      } else {
        toastr.error(response.data.message);
      }
    } catch (error) {
      toastr.error("Error fetching admin details.");
    }
  };

  const handleApproveAdmin = async (adminMobNo, action) => {
    console.log("adminId", adminMobNo);
    const api = action === "approve" 
      ? `${process.env.REACT_APP_BASE_URL}SuperAdminApproveAdmin` 
      : `${process.env.REACT_APP_BASE_URL}/SuperAdminRemoveAdmin`;
    const req_json = { mobileno: adminMobNo };
  
    try {
      const response = await apiPostRequest(api, req_json);
      if (response.data.success) {
        toastr.success(response.data.message);
        setUserDetails((prevDetails) =>
          prevDetails.map((user) =>
            user.mobileno === adminMobNo 
              ? { ...user, approval_status: action === "approve" } 
              : user
          )
        );
      } else {
        toastr.info(response.data.message);
      }
    } catch (error) {
      toastr.error(`Error ${action === "approve" ? "approving" : "removing"} admin.`);
    }
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAdminLogout = () => {
    localStorage.clear();
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    localStorage.setItem("isAdminLoggedIn", false);
    navigate("/");
  };

  return (
    <>
      <div
        className="d-flex p-2 mb-5"
        style={{
          justifyContent: "space-between",
          color: "rgb(52, 58, 64)",
          backgroundColor: "rgb(155, 171, 7)",
          boxShadow: "rgba(0,0,0,0.1) 0px 0.5rem 1rem",
          position: "sticky",
          top: "0px",
          transition: "all 0.5s ease 0s",
          zIndex: 1020,
        }}
      >
        <header style={{ fontSize: "4vh", fontWeight: "bold" }}>Super Admin Page</header>
        <Button
          onClick={handleAdminLogout}
          style={{
            color: "rgb(52, 58, 64)",
            fontWeight: "700",
            fontSize: "2.5vh",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          Logout
        </Button>
      </div>
      <div style={{ margin: "20px" }}>
        <Paper sx={{ width: "100%", overflow: "auto", marginTop: "32px" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.minWidth, color: "black", fontWeight: "bold" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userDetails
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      {columns.map((column) => (
                        <TableCell key={column.id}>
                          {column.id === "approval_status" ? (
                            !row.approval_status ? (
                              <Button
                                style={{ backgroundColor: "orange", color: "white" }}
                                onClick={() => handleApproveAdmin(row.mobileno, "approve")}
                              >
                                Approve
                              </Button>
                            ) : (
                              <Button
                                style={{ backgroundColor: "orange", color: "white" }}
                                onClick={() => handleApproveAdmin(row.mobileno,"remove")}
                              >
                                Remove
                              </Button>
                            )
                          ) : (
                            row[column.id]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={userDetails.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}
