import React, { useEffect, useState, useRef } from "react";
import { apiGetRequest, apiPostRequest } from "../../../utils/Network";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import * as imports from "../../../Imports";
import { Refresh } from "@mui/icons-material";
import Loader from "../../../components/loader/Loader";

export default function AdminMainPage() {
  const [userDetails, setUserDetails] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [modulestatuswithtime, setModulestatuswithtime] = useState([]);
  const [moduleColumn, setModuleColumn] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [hasModuleData, setHasModuleData] = useState(true);
  const [currentDate, setCurrentDate] = useState(null);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  toastr.options = {
    closeButton: true,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    timeOut: 3000,
  };

  const handleAdminLogout = () => {
    localStorage.clear();
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    localStorage.setItem("isAdminLoggedIn", false)
    navigate('/');
  }

  const count = useRef(0);

  const fetchStudentDetails = async () => {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}/studentdetailsforadmin`;
      const response = await apiGetRequest(api);

      if (response.data.success === true) {
        const rows = response.data.student_details.map(student => {
          const row = {};
          Object.keys(student).forEach(key => {
            let value = student[key];
            if (typeof value === 'object' && value !== null && '$oid' in value) {
              value = value.$oid;
            }
            row[key] = value === null || value === undefined ? '' : value;
          });
          return row;
        });

        const columns = Object.keys(response.data.student_details[0]).map((key) => ({
          id: key,
          label: key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
          minWidth: 120, // Reduced minWidth
        }));

        setColumns(columns);
        setUserDetails(rows);
        setFeedbackData(response.data.feedback); // Set feedback data from the response
      } else {
        toastr.error(response.data.message);
      }
    } catch (error) {
      toastr.error("There is no data in DB!!");
    }
  }

  const handleSelectStudent = async (event) => {
    setSelectedStudent(event.target.value);
    try {
      const api =`${process.env.REACT_APP_BASE_URL}/eachstudentmodulestatus`;
      const req_json = {
        "user_id": event.target.value
      }
      const response = await apiPostRequest(api, req_json);
      const cd = response.data.current_date
      if (cd) {
        setCurrentDate(response.data.current_date)
      } else {
        setCurrentDate(null)
      }

      if (response.data.modules.length === 0) {

        setHasModuleData(false);
        toastr.warning("The selected student has not yet completed any module tests.");
        setModulestatuswithtime([]);
        setModuleColumn([]);
        return;
      }

      const modulecolumn = [];
      const modulerows = {
        minutes: { label: 'Minutes', data: {} },
        seconds: { label: 'Seconds', data: {} }
      };

      response.data.modules.forEach((data) => {
        Object.keys(data).forEach((key) => {
          modulecolumn.push({ id: key, label: key, minWidth: 120 }); // Reduced minWidth
          modulerows.minutes.data[key] = data[key][1].minute;
          modulerows.seconds.data[key] = data[key][1].second;
        });
      });

      setModuleColumn(modulecolumn);
      setModulestatuswithtime([modulerows.minutes, modulerows.seconds]);
      setHasModuleData(true);
    } catch (error) {
      toastr.error("There is an internal error! Please logout and login.");
    }
  }

  useEffect(() => {
    if (
      !JSON.parse(localStorage.getItem("isAdminLoggedIn"))) {
      navigate("/")
    }
    if (count.current === 0) {
      fetchStudentDetails();
      count.current += 1;
    }


  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleApprove = async (mobilenumber) => {
    let api = `${process.env.REACT_APP_BASE_URL}/admin/approve`;  //  the URL to match your Flask route
    let req_json = { "mobilenumber": mobilenumber };  // Send the mobilenumber in the request body
  
    try {
      const response = await apiPostRequest(api, req_json);
  
      if (response && response.data && response.data.success) {
        // Check if response, response.data, and response.data.success exist
        toastr.success(response.data.message);
        // Handle success (e.g., update the UI to reflect the approval)
        setUserDetails(prevDetails => 
          prevDetails.map(user => 
            user.mobilenumber === mobilenumber ? { ...user, approval_status: true } : user
          )
         
        );
        window.location.reload();

      } else {
        toastr.error(response.data.message); // Use toastr.error for error messages
      }
    } catch (error) {
      toastr.error('An error occurred while approving the student.'); // Show error toast
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="d-flex p-2 mb-5" style={{ justifyContent: "space-between", color: "white", backgroundColor: "rgb(255, 135, 118)", boxShadow: "rgba(0,0,0,0.1) 0px 0.5rem 1rem", position: "sticky", top: "0px", transition: "all 0.5s ease 0s", zIndex: 1020 }}>
        <header style={{ fontSize: "4vh", fontWeight: "bold" }}>Admin Page</header>
        <imports.Button onClick={handleAdminLogout} style={{ color: "white", fontWeight: "700", fontSize: "2.5vh", textDecoration: "none", cursor: "pointer" }}>Logout</imports.Button>
      </div>
      <div style={{ margin: "20px" }}>
        <imports.Paper sx={{ width: '100%', overflow: 'auto', marginTop: '32px' }} >
          <imports.TableContainer sx={{ maxHeight: 440 }}>
            <imports.Table stickyHeader aria-label="sticky table">
              <imports.TableHead>
                <imports.TableRow >
                  {columns.slice(0, -1).map((column) => (
                    <imports.TableCell key={column.id} style={{ minWidth: column.minWidth, color: "black", fontWeight: "bold" }}>
                      {column.label}
                    </imports.TableCell>
                  ))}
                  <imports.TableCell key="feedback" style={{ color: "black", fontWeight: "bold" }}>Feedback</imports.TableCell>
                </imports.TableRow>
              </imports.TableHead>
              <imports.TableBody>
                {userDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <imports.TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <imports.TableCell key={column.id} align={column.align}>
                          {column.id === 'approval_status' ? (
                            // Check if approval_status is false to render the button
                            value === false ? (
                              <button
                                style={{ backgroundColor: 'orange', color: 'white', border: 'none', padding: '5px 10px', cursor: 'pointer' }}
                                onClick={() => handleApprove(row.mobilenumber)}
                              >
                                Approve
                              </button>
                            ) : (
                              'Approved'
                            )
                          ) : (
                            // Render normal cell content for other columns
                            column.format && typeof value === 'number' ? column.format(value) : value
                          )}
                        </imports.TableCell>
                      );
                    })}
                    <imports.TableCell>
                      {feedbackData.find(feedback => feedback.user_id === row.mobilenumber)?.feedback}
                    </imports.TableCell>
                  </imports.TableRow>
                ))}
              </imports.TableBody>
            </imports.Table>
          </imports.TableContainer>
          <imports.TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </imports.Paper>

        <div className="md-12" style={{ float: "left" }}>
          <imports.FormControl sx={{ mb: 1, minWidth: 120, marginTop: 6 }} size="small">
            <imports.InputLabel id="demo-select-small-label">Students</imports.InputLabel>
            <imports.Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedStudent}
              label="Students"
              onChange={handleSelectStudent}
            >
              {userDetails.map((val, index) => (
                <imports.MenuItem value={val.mobilenumber} key={index}>
                  {val.studentname.split(" ")[0] + ":" + " " + val.mobilenumber}
                </imports.MenuItem>
              ))}
            </imports.Select>
          </imports.FormControl>

          {currentDate != null && <div className="mt-2">
            <p><span style={{ fontWeight: "bold" }}>Current Date: </span>{currentDate}</p>
          </div>}
        </div>

        {selectedStudent && hasModuleData ? (
          <imports.Paper sx={{ width: '100%', overflow: 'auto', marginTop: '32px' }}>
            <imports.TableContainer sx={{ maxHeight: 440 }}>
              <imports.Table stickyHeader aria-label="sticky table">
                <imports.TableHead>
                  <imports.TableRow style={{ backgroundColor: "rgb(218, 169, 162)", color: "white" }}>
                    <imports.TableCell style={{ color: "black", fontWeight: "bold" }}>Time</imports.TableCell>
                    {moduleColumn.map((column) => (
                      <imports.TableCell key={column.id} style={{ minWidth: column.minWidth, color: "black", fontWeight: "bold" }} >
                        {column.label}
                      </imports.TableCell>
                    ))}
                  </imports.TableRow>
                </imports.TableHead>
                <imports.TableBody>
                  {modulestatuswithtime.map((row, index) => (
                    <imports.TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <imports.TableCell>{row.label}</imports.TableCell>
                      {moduleColumn.map((column) => (
                        <imports.TableCell key={column.id}>
                          {row.data[column.id]}
                        </imports.TableCell>
                      ))}
                    </imports.TableRow>
                  ))}
                </imports.TableBody>
              </imports.Table>
            </imports.TableContainer>
            <imports.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={modulestatuswithtime.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </imports.Paper>
        ) : null}

      </div>
    </>
  )
}
