import React, { useState, useEffect } from "react";
import DynamicLayout from "../../dynamic-layout/DynamicLayout";
import InputField from "../../../components/input/InputField";
import RadioOptions from "../../../components/radiogroup/RadioOptions";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";
import Loader from "../../../components/loader/Loader";
import "./Registration.css";
import { apiPostRequest } from "../../../utils/Network";
import Dialog from "../../../components/dialogue/Dialog";
import LockResetIcon from "@mui/icons-material/LockReset";

export default function Registration(props) {
  console.log("propsssssss", props.type);
  toastr.options = {
    closeButton: true,
    positionClass: "toast-top-right",
    preventDuplicates: true,
    timeOut: 3000,
  };
  const navigate = useNavigate();

  const [registrationDetails, setRegistrationDetails] = useState({
    name: "",
    school: "",
    studentClass: "",
    rollNo: "",
    birthDate: "",
    motherEducation: "",
    fatherEducation: "",
    motherOccupation: "",
    fatherOccupation: "",
    religion: "",
    mobilenumber: "",
    password: "",
    location: "",
    fatherName: "",
    motherName: "",
    caste: "",
    disabled: true,
  });
  const [loader, setLoader] = useState(false);
  const [genderResidence, setGenderResidence] = useState({
    Q1: "",
    Q2: "",
    Q3: "",
  });
  const [removeBorder, setRemoveBorder] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(true);
  const [adminCredentials, setAdminCredentials] = useState({
    name: "",
    number: "",
    place: "",
    email: "",
    password: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("RegistrationPopupShown")) {
      setOpen(!open);
      localStorage.setItem("RegistrationPopupShown", "true");
    }
  }, []);

  const handleChangeRegistration = (e) => {
    const { id, value } = e.target;

    setRegistrationDetails((prevUserInputs) => ({
      ...prevUserInputs,
      [id]: value,
    }));

    const onlyEcceptTextFrm = /^[a-zA-Z\s]*$/;
    const bothCharandNumFrm = /^[a-zA-Z0-9\s.]*$/;
    let mobileNumberFrmt = /^\+(?:[0-9]{12})$|^[0-9]{10}$/;
    let passwordFrmt =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (e.target.id === "studentname") {
      const textFrmTest = bothCharandNumFrm.test(value);
      if (textFrmTest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          name: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid Student Name");
      }
    }

    if (e.target.id === "rollNumber") {
      const textFrmTest = bothCharandNumFrm.test(value);
      if (textFrmTest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          rollNo: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid Roll No");
      }
    }

    if (e.target.id === "location") {
      const textFrmTest = onlyEcceptTextFrm.test(value);
      if (textFrmTest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          location: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid location");
      }
    }

    if (e.target.id === "fathername") {
      const textFrmTest = onlyEcceptTextFrm.test(value);
      if (textFrmTest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          fatherName: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid father name");
      }
    }
    if (e.target.id === "caste") {
      const textFrmTest = onlyEcceptTextFrm.test(value);
      if (textFrmTest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          caste: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid caste");
      }
    }
    if (e.target.id === "mothername") {
      const textFrmTest = onlyEcceptTextFrm.test(value);
      if (textFrmTest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          motherName: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid Mother Name");
      }
    }

    if (e.target.id === "password") {
      const textFrmTest = passwordFrmt.test(value);
      if (textFrmTest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          password: e.target.value,
        }));
      } else {
        toastr.error(
          "Make a combination of any character using (!,@,#,$,%,&,*) and a number. Example: user@123"
        );
      }
    }

    if (e.target.id === "mobilenumber") {
      const textFrmTest = mobileNumberFrmt.test(value);
      if (textFrmTest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          mobilenumber: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid mobile number");
      }
    }

    if (e.target.id === "schoolname") {
      const textFrmTest = bothCharandNumFrm.test(value);
      if (textFrmTest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          school: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid School Name");
      }
    }

    if (e.target.id === "class") {
      const bothCharandNumtest = bothCharandNumFrm.test(value);
      if (bothCharandNumtest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          studentClass: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid class");
      }
    }

    if (e.target.id === "dateofbirth") {
      setRegistrationDetails((prevUserInputs) => ({
        ...prevUserInputs,
        birthDate: e.target.value,
      }));
    }

    if (e.target.id === "monthereducation") {
      const bothCharandNumtest = bothCharandNumFrm.test(value);
      if (bothCharandNumtest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          motherEducation: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid Mother Education");
      }
    }

    if (e.target.id === "fathereducation") {
      const bothCharandNumtest = bothCharandNumFrm.test(value);
      if (bothCharandNumtest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          fatherEducation: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid Father Education");
      }
    }

    if (e.target.id === "motheroccupation") {
      const bothCharandNumtest = onlyEcceptTextFrm.test(value);
      if (bothCharandNumtest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          motherOccupation: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid Mother Occupation");
      }
    }

    if (e.target.id === "fatheroccupation") {
      const bothCharandNumtest = onlyEcceptTextFrm.test(value);
      if (bothCharandNumtest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          fatherOccupation: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid Father Occupation");
      }
    }

    if (e.target.id === "religiondropdown") {
      const bothCharandNumtest = onlyEcceptTextFrm.test(value);
      if (bothCharandNumtest) {
        setRegistrationDetails((prevUserInputs) => ({
          ...prevUserInputs,
          religion: e.target.value,
        }));
      } else {
        toastr.error("Please enter valid religion");
      }
    }
  };

  const handleRemoveborder = async () => {
    setLoader(true);
    if (props.type === "User Registration") {
      try {
        let api = `${process.env.REACT_APP_BASE_URL}/StudentRegister`;

        const req_json = {
          userRegistartion: true,
          studentname: registrationDetails.name,
          studentclass: registrationDetails.studentClass,
          schoolname: registrationDetails.school,
          dob: registrationDetails.birthDate,
          gender: genderResidence.Q1,
          religion: genderResidence.Q2,
          caste: registrationDetails.caste,
          location: registrationDetails.location,
          familytype: genderResidence.Q3,
          mothereducation: registrationDetails.motherEducation,
          fathereducation: registrationDetails.fatherEducation,
          motheroccupation: registrationDetails.motherOccupation,
          fatheroccupation: registrationDetails.fatherOccupation,
          fathername: registrationDetails.fatherName,
          mothername: registrationDetails.motherName,
          mobilenumber: registrationDetails.mobilenumber,
          password: registrationDetails.password,
          rollno: registrationDetails.rollNo,
        };

        const response = await apiPostRequest(api, req_json);
        if (response.data.success == true) {
          toastr.success(
            "Your registration has been successfully submitted to the admin for login approval."
          );

          setTimeout(() => {
            localStorage.setItem("isStudentRegistered", true);
            setLoader(false);

            navigate("/Login");
          }, 1500);
        } else {
          setLoader(false);
          toastr.error(response.data.message);
        }
      } catch {
        toastr.error("There is an internal error! Please logout and login.");
      }
    } else {
      const req_json = {
        password: registrationDetails.password,
        name: registrationDetails.name,
        mobileno: registrationDetails.mobilenumber,
        place: registrationDetails.location,
      };
      try {
        let api = `${process.env.REACT_APP_BASE_URL}/AdminRegister`;
        const response = await apiPostRequest(api, req_json);

        if (response.data.success == true) {
          setTimeout(() => {
            localStorage.setItem("isAdminRegistered", true);
            setLoader(false);
            navigate("/Login");
          }, 1500);
        } else {
          setLoader(false);
          toastr.error(response.data.message);
        }
      } catch {
        toastr.error("There is an internal error! Please logout and login.");
      }
    }
  };

  const radioValues = [
    {
      feedback_title: "",
      selectedValue: genderResidence.Q1,
      qNo: "Q1",
      radioLabels: {
        labels: [
          {
            value: "Male",
            label: "Male",
          },
          {
            value: "Female",
            label: "Female",
          },
        ],
      },
      name: "radio1",
    },
    {
      feedback_title: "",
      selectedValue: genderResidence.Q2,
      qNo: "Q2",
      radioLabels: {
        labels: [
          {
            value: "Rural",
            label: "Rural",
          },
          {
            value: "Urban",
            label: "Urban",
          },
        ],
      },
      name: "radio2",
    },
    {
      feedback_title: "",
      selectedValue: genderResidence.Q3,
      qNo: "Q3",
      radioLabels: {
        labels: [
          {
            value: "Nuclear",
            label: "Nuclear",
          },
          {
            value: "Joint",
            label: "Joint",
          },
        ],
      },
      name: "radio3",
    },
  ];

  const handleAdolescentRadioChange = (e, questionNumber) => {
    setGenderResidence((prevState) => ({
      ...prevState,
      [questionNumber]: e.target.value,
    }));
  };

  const handleAdminCredentials = (e, valueType) => {
    const { id, value } = e.target;
    console.log("iudd", id);
    console.log("values", value);
    setAdminCredentials((prevUserInputs) => ({
      ...prevUserInputs,
      [id]: value,
    }));
    const onlyAcceptTextFrm = /^[a-zA-Z\s]*$/;
    const bothCharAndNumFrm = /^[a-zA-Z0-9\s.]*$/;
    let mobileNumberFrmt = /^\+(?:[0-9]{12})$|^[0-9]{10}$/;
    let passwordFrmt =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    let emailFrmt = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (e.target.id === "adminpassword") {
      console.log("::yes");
      const textFrmTest = passwordFrmt.test(value);
      if (textFrmTest) {
        console.log("::nnoooo");
        setAdminCredentials((prevUserInputs) => ({
          ...prevUserInputs,
          password: value,
        }));
      } else {
        toastr.error(
          "Make a combination of any character using (!,@,#,$,%,&,*), number and capital letter. Example: User@123"
        );
      }
    }

    if (e.target.id === "adminnumber") {
      const textFrmTest = mobileNumberFrmt.test(value);
      if (textFrmTest) {
        console.log("yes mb");
        setAdminCredentials((prevUserInputs) => ({
          ...prevUserInputs,
          number: value,
        }));
      } else {
        toastr.error("Please enter valid mobile number with 10 numbers");
      }
    }

    if (e.target.id === "adminame") {
      console.log("yess namaa");
      const textFrmTest = onlyAcceptTextFrm.test(value);
      if (textFrmTest) {
        console.log("yes text");
        setAdminCredentials((prevUserInputs) => ({
          ...prevUserInputs,
          name: value,
        }));
      } else {
        toastr.error("Please enter valid Mother Name");
      }
    }

    if (e.target.id === "adminplace") {
      console.log("placee");
      const textFrmTest = onlyAcceptTextFrm.test(value);
      if (textFrmTest) {
        console.log("placee");
        setAdminCredentials((prevUserInputs) => ({
          ...prevUserInputs,
          place: value,
        }));
      } else {
        toastr.error("Please enter valid location");
      }
    }

    if (e.target.id === "adminemail") {
      const textFrmTest = emailFrmt.test(value);
      if (textFrmTest) {
        setAdminCredentials((prevUserInputs) => ({
          ...prevUserInputs,
          email: value,
        }));
      } else {
        toastr.error("Please enter a valid email address");
      }
    }
  };

  const allowAdminLogin = async () => {
    try {
      const api = `${process.env.REACT_APP_BASE_URL}/AdminRegister`;
      const req_json = {
        name: adminCredentials.name,
        email: adminCredentials.email,
        password: adminCredentials.password,
        mobileno: adminCredentials.number,
        place: adminCredentials.place,
      };

      const response = await apiPostRequest(api, req_json);

      if (response.data && response.data.success) {
        toastr.info(
          "You admin registration request has been sent to super admin"
        );
        setIsAdminLoggedIn(!isAdminLoggedIn);
        navigate("/login");
      } else {
        toastr.error(response.data.message || "Login failed");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      toastr.error("Please enter valid credentials");
    }
  };

  return (
    <DynamicLayout header={""} layout={false}>
      {loader && <Loader />}
      {props.type === "User Registration" ? (
        <div className="col-md-12 p-5">
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "#383841",
              textAlign: "left",
            }}
          >
            Enter the Student Details to Proceed
          </p>
          <div className="mt-4">
            <InputField
              type="text"
              id="studentname"
              value={registrationDetails.name}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter student name"
            />
          </div>
          <div className="mt-4">
            <InputField
              type="text"
              id="schoolname"
              value={registrationDetails.school}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter school name"
            />
          </div>
          <div className="mt-4">
            <InputField
              type="text"
              id="class"
              value={registrationDetails.studentClass}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter your class"
            />
          </div>
          <div className="mt-4">
            <InputField
              type="number"
              id="rollNumber"
              value={registrationDetails.rollNo}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter your Roll No"
            />
          </div>
          <div style={{ display: "flex" }} className="mt-4"></div>
          <div>
            <InputField
              type="date"
              id="dateofbirth"
              value={registrationDetails.birthDate}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter Your birth date"
            />
          </div>

          <div style={{ display: "flex" }} className="mt-4">
            <InputField
              type="location"
              id="location"
              value={registrationDetails.location}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter your current location"
            />
          </div>
          <div style={{ display: "flex" }} className="mt-4">
            <InputField
              type="fathername"
              id="fathername"
              value={registrationDetails.fatherName}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter father name"
            />
          </div>

          <div style={{ display: "flex" }} className="mt-4">
            <InputField
              type="mothername"
              id="mothername"
              value={registrationDetails.motherName}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter mother name"
            />
          </div>
          <div className="mt-4">
            <InputField
              type="text"
              id="monthereducation"
              value={registrationDetails.motherEducation}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter mother education"
            />
          </div>
          <div className="mt-4">
            <InputField
              type="text"
              id="fathereducation"
              value={registrationDetails.fatherEducation}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter father education"
            />
          </div>
          <div className="mt-4">
            <InputField
              type="text"
              id="motheroccupation"
              value={registrationDetails.motherOccupation}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter mother occupation"
            />
          </div>
          <div className="mt-4">
            <InputField
              type="text"
              id="fatheroccupation"
              value={registrationDetails.fatherOccupation}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter father occupation"
            />
          </div>

          <div className="mt-4">
            <InputField
              type="number"
              id="mobilenumber"
              value={registrationDetails.mobilenumber}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter mobile number"
            />
          </div>
          <div className="mt-4">
            <InputField
              type="password"
              id="password"
              value={registrationDetails.password}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Password"
            />
          </div>

          <div className="mt-4">
            <InputField
              type="religion"
              id="religiondropdown"
              value={registrationDetails.religion}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter your religion"
            />
          </div>

          <div className="mt-4">
            <InputField
              type="caste"
              id="caste"
              value={registrationDetails.caste}
              fullWidth={true}
              onChange={handleChangeRegistration}
              placeholder="Enter your caste"
            />
          </div>

          <div className="radioalignment">
            {radioValues.map((data, i) => (
              <div className="d-flex p-3">
                <RadioOptions
                  selectedValue={data.selectedValue}
                  name={data.name}
                  data={data.radioLabels.labels}
                  onChange={handleAdolescentRadioChange}
                  qNo={data.qNo}
                />
              </div>
            ))}
          </div>

          <div style={{ display: "flex", justifyContent: "left " }}></div>

          <div
            className={removeBorder ? "removeborder row" : "row"}
            style={{ justifyContent: "center", marginTop: "12px" }}
          >
            <button
              style={{
                backgroundColor: "rgb(254, 136, 117)",
                padding: "8px",
                width: "30vh",
                marginTop: "12px",
                color: "white",
                border: "none",
                borderRadius: "22px",
                cursor: "pointer",
                opacity:
                  registrationDetails.name &&
                  registrationDetails.school &&
                  registrationDetails.studentClass &&
                  registrationDetails.birthDate &&
                  registrationDetails.motherEducation &&
                  registrationDetails.fatherEducation &&
                  registrationDetails.motherOccupation &&
                  registrationDetails.fatherOccupation &&
                  registrationDetails.religion &&
                  registrationDetails.password &&
                  registrationDetails.motherName &&
                  registrationDetails.fatherName &&
                  registrationDetails.mobilenumber &&
                  registrationDetails.caste &&
                  genderResidence.Q2 &&
                  genderResidence.Q3
                    ? "1"
                    : "0.3",
              }}
              disabled={
                registrationDetails.name &&
                registrationDetails.school &&
                registrationDetails.studentClass &&
                registrationDetails.birthDate &&
                registrationDetails.motherEducation &&
                registrationDetails.fatherEducation &&
                registrationDetails.motherOccupation &&
                registrationDetails.fatherOccupation &&
                registrationDetails.religion &&
                registrationDetails.password &&
                registrationDetails.motherName &&
                registrationDetails.fatherName &&
                registrationDetails.mobilenumber &&
                registrationDetails.caste &&
                genderResidence.Q1 &&
                genderResidence.Q2 &&
                genderResidence.Q3
                  ? false
                  : true
              }
              onClick={handleRemoveborder}
              className="registerbutton"
            >
              Register Now
            </button>
          </div>
        </div>
      ) : (
        <div className="col-md-12 p-5">
          {" "}
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "#383841",
              textAlign: "left",
            }}
          >
            Please Enter Admin credentials
          </p>
          <div className="mt-4">
            <InputField
              type="text"
              id="adminame"
              value={adminCredentials[0]}
              fullWidth={true}
              onChange={(e) => handleAdminCredentials(e, "name")}
              placeholder="Admin name"
            />
          </div>
          <div className="mt-4">
            <InputField
              type="number"
              id="adminnumber"
              value={adminCredentials[1]}
              fullWidth={true}
              onChange={(e) => handleAdminCredentials(e, "number")}
              placeholder="Admin mobile number"
            />
          </div>
          <div className="mt-4">
            <InputField
              type="text"
              id="adminplace"
              value={adminCredentials[2]}
              fullWidth={true}
              onChange={(e) => handleAdminCredentials(e, "place")}
              placeholder="Admin place"
            />
          </div>
          <div className="mt-4">
            <InputField
              type="text"
              id="adminemail"
              value={adminCredentials[3]}
              fullWidth={true}
              onChange={(e) => handleAdminCredentials(e, "email")}
              placeholder="Enter Admin Email Address"
            />
          </div>
          <div className="mt-4">
            <InputField
              type="password"
              id="adminpassword"
              value={adminCredentials[4]}
              fullWidth={true}
              onChange={(e) => handleAdminCredentials(e, "password")}
              placeholder="Enter Password"
              icon={LockResetIcon}
            />
          </div>
          {/* <div style={{ textAlign: "right" }}>
            {" "}
            <a href="#" style={{ float: "right", fontSize: "12px" }}>
              {" "}
              forgot password
            </a>
          </div> */}
          <div
            className={removeBorder ? "removeborder row" : "row"}
            style={{ justifyContent: "center", marginTop: "12px" }}
          >
            <button
              style={{
                backgroundColor: "rgb(254, 136, 117)",
                padding: "8px",
                width: "20vh",
                marginTop: "12px",
                color: "white",
                border: "none",
                borderRadius: "22px",
                cursor: "pointer",
              }}
              onClick={allowAdminLogin}
              className="registerbutton"
            >
              Submit
            </button>
          </div>
        </div>
      )}
      {open ? (
        <Dialog
          handleClose={handleClose}
          open={open}
          title={"Dear Students,"}
          content={
            "Kindly fill all the details to complete the registration process"
          }
        />
      ) : (
        <> </>
      )}
    </DynamicLayout>
  );
}
