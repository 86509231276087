import React, { useEffect, useState } from "react";
import DynamicLayout from "../../dynamic-layout/DynamicLayout";
import "../Login.css";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Loader from "../../../components/loader/Loader";

export default function SuperAdminLogin() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [superAdminInputs, setSuperAdminInputs] = useState({
    email: "",
    password: "",
  });

  const handleInputs = (e) => {
    const { id, value } = e.target;
    setSuperAdminInputs((prevInputs) => ({
      ...prevInputs,
      [id]: value,
    }));
  };

  const onLogin = () => {
    setLoader(true);
    if (
      superAdminInputs.email === "shivashankar.a.huddar@gmail.com" &&
      superAdminInputs.password === "superAdmin@123@&."
    ) {
      localStorage.setItem("isSuperAdminLoggedIn", true);
      setLoader(false);
      navigate("/SuperAdmin");
    } else {
      setLoader(false);
      toastr.error("Please enter valid super admin credentials");
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("isSuperAdminLoggedIn")) === true) {
    //   navigate("/super-admin");
    }
  }, [navigate]);

  return (
    <>
      <div style={{ float: "right", fontSize: "large" }} className="m-1">
        <button
          style={{
            border: "none",
            backgroundColor: "transparent",
            color: "#ffc107", // Yellow color to indicate importance
            textDecoration: "underline",  
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          Home
        </button>
      </div>
      <br />
      <div className="mt-5">
        <DynamicLayout
          header={"Super Admin Login"}
        backgroundColor={"#343a40"}// Dark background for the layout
        color={"#ffc107"} // White text color for better contrast
        >
          {loader && <Loader />}
          <div
            className="col-md-12 p-4 loginforTab"
            style={{
              backgroundColor: "#495057", // Slightly lighter dark background for the form container
              borderRadius: "10px",
              padding: "2rem",
            }}
          >
            <input
              className="textfield"
              onChange={handleInputs}
              type="email"
              id="email"
              value={superAdminInputs.email}
              placeholder="Super Admin Email"
              style={{
                marginTop: "10%",
                width: "100%",
                borderRadius: "10px",
                padding: "12px",
                border: "1px solid #ced4da",
                backgroundColor: "#212529", // Darker input background
                color: "#ffffff", // White text color
                marginBottom: "1rem",
              }}
            />
            <input
              className="textfield"
              onChange={handleInputs}
              type="password"
              id="password"
              value={superAdminInputs.password}
              placeholder="Super Admin Password"
              style={{
                width: "100%",
                borderRadius: "10px",
                padding: "12px",
                border: "1px solid #ced4da",
                backgroundColor: "#212529", // Darker input background
                color: "#ffffff", // White text color
                marginBottom: "2rem",
              }}
            />
            <button
              className="loginBtn"
              disabled={!superAdminInputs.email || !superAdminInputs.password}
              onClick={onLogin}
              style={{
                backgroundColor: "#ffc107", // Yellow button for visibility
                color: "#343a40", // Dark text color on yellow
                padding: "12px 20px",
                borderRadius: "10px",
                border: "none",
                width: "100%",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              Super Admin Login
            </button>
          </div>
        </DynamicLayout>
      </div>
    </>
  );
}
