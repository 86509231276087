import React, { useState } from 'react';
import './LoginTabs.css';
import Layout from '../layout/Layout';
import { useNavigate } from 'react-router-dom';

const LoginTabs = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(null);

  const loginNavigation = (navigateTo) => {
    let navigationURL;
    if (navigateTo === "user") {
      navigationURL = "/Login/UserLogin";
    } else if (navigateTo === "admin") {
      navigationURL = "/Login/AdminLogin";
    } else if (navigateTo === "superAdmin") {
      navigationURL = "/Login/SuperAdminLogin";
    }
    navigate(navigationURL);
  };

  return (
    <>
      <Layout login={true} />
      <div className="login-tabs-container">
        <h2 style={{
          marginBottom: "20px",
          color: "rgb(254, 136, 117)",
          fontWeight: "bold",
          position: "relative",
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
        }}>Mind Aid</h2>
        <div className="login-tabs" style={{justifyContent:"space-around"}}>
          <button
            className={`tab ${activeTab === 'user' ? 'active' : ''}`}
            onClick={() => loginNavigation('user')}
            style={{ margin: "0px" }}
          >
            Student Login
          </button>
          <button
            className={`tab ${activeTab === 'admin' ? 'active' : ''}`}
            onClick={() => loginNavigation('admin')}
            style={{ margin: "0px" }}
          >
            Admin Login
          </button>
         
          <button
            className={`tab ${activeTab === 'superAdmin' ? 'active' : ''}`}
            onClick={() => loginNavigation('superAdmin')}
            style={{ margin: "0px" }}
          >
            Super Admin Login
          </button>
          
        </div>
      </div>
    </>
  );
};

export default LoginTabs;
