import React from "react";
import "./Videos.css";
import AngerManagement from "./../../videos/AngerManagement.mp4";
import AngerManagement1 from "./../../videos/AngerManagement2.mp4";

export default function Videos() {
  return (
    <div className="m-3 about-videos-main">
      <h2 className="about-videos-section-title">Videos</h2>
      <div className="about-videos-section p-3">
        {[AngerManagement, AngerManagement1, AngerManagement1].map((video, videoIndex) => (
          <video
          key={videoIndex}
            className="about-video col-md-5 me-2"
            src={video}
            title="YouTube video player"
            controls
            autoPlay={false}
            preload="none"
          ></video>
        ))}
      </div>
    </div>
  );
}
