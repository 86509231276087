import React from "react";

export default function TrackedScreen(){

  
  return (
    <>

    </>
  )
}