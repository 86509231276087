import React, { useEffect } from 'react';
import * as imports from "../../Imports";
import toastr from 'toastr';
import { apiPostRequest, apiGetRequest } from '../../utils/Network';
import Loader from '../loader/Loader';
import Popup from "../popup/Popup";
import "./Feedback.css";

export default function ExampleTextareaCommentPopup({handleClose}) {
  const [italic, setItalic] = React.useState(false);
  const [fontWeight, setFontWeight] = React.useState('normal');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false); // Initially not open
  const [comment, setComment] = React.useState('');
  const [certificate, setCertificate] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const handleTextareaChange = (event) => {
    const value = event.target.value;
    
    if (value.length < 5 || value.length > 30) {
      toastr.error('Please enter a minimum of 5 and a maximum of 30 characters.');
    }
    setComment(value);
  };
  
  const apiCall = async () => {
    const api = `${process.env.REACT_APP_BASE_URL}/user_list`;
    try {
      const response = await apiGetRequest(api);
      if (response.data.success) {
        const message = response.data.message;
  
        // Check if any user has a feedback and certificate downloaded status
        const feedbackExists = message.some((student) => student.feedback);
        const certificateDownloaded = message.some(
          (student) => student.certificate_downloaded
        );
  
        // If the certificate has not been downloaded and feedback exists, show the certificate
        if (feedbackExists && !certificateDownloaded) {
          setCertificate(true); // Show certificate if feedback exists and it has not been downloaded
        } else if (!feedbackExists) {
          setOpen(true); // Show feedback form if feedback does not exist
        }
      } else {
        toastr.error("Failed to fetch student list");
      }
    } catch (error) {
      console.error("Error in apiCall:", error);
      toastr.error("An error occurred while fetching student list");
    }
  };
  

  useEffect(() => {
    apiCall(); // Check for feedback existence on mount
  }, []);

  const handleSend = async () => {
    const api =`${process.env.REACT_APP_BASE_URL}/feedback`;
    const req_json = { "feedback": comment };
    setLoader(true);

    try {
      const response = await apiPostRequest(api, req_json);
      if (response.data.success) {
        localStorage.setItem("feedback", true);
        setCertificate(true); // Show certificate on successful feedback submission
      } else {
        localStorage.setItem("feedback", false);
      }
    } catch (error) {
      console.error("Error in handleSend:", error);
      toastr.error("An error occurred while submitting feedback");
    } finally {
      setLoader(false);
      setOpen(false); // Close the feedback form after submission
    }
  };

  return (
    <div>
      {loader && <Loader />}
      <imports.Modal
        open={open}
        onClose={() => {}}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <imports.Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            maxWidth: '392px',
            width: '100%',
          }}
          className='feedbackscreen'
        >
          <imports.FormLabel sx={{ mb: 2, fontSize: 's' }}>
            Please Provide Your Valuable Feedback!
          </imports.FormLabel>
          <imports.FormControl>
            <imports.Textarea
              placeholder="Type something here…"
              minRows={3}
              value={comment}
              onChange={handleTextareaChange}
              endDecorator={
                <imports.Box
                  sx={{
                    display: 'flex',
                    gap: 'var(--Textarea-paddingBlock)',
                    pt: 'var(--Textarea-paddingBlock)',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    flex: 'auto',
                  }}
                >
                  <imports.IconButton
                    variant="plain"
                    color="neutral"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    <imports.FormatBold />
                    <imports.KeyboardArrowDown fontSize="md" />
                  </imports.IconButton>
                  <imports.Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    size="sm"
                    placement="bottom-start"
                    sx={{ '--ListItemDecorator-size': '24px' }}
                  >
                    {['200', 'normal', 'bold'].map((weight) => (
                      <imports.MenuItem
                        key={weight}
                        selected={fontWeight === weight}
                        onClick={() => {
                          setFontWeight(weight);
                          setAnchorEl(null);
                        }}
                        sx={{ fontWeight: weight }}
                      >
                        <imports.ListItemDecorator>
                          {fontWeight === weight && <imports.Check fontSize="sm" />}
                        </imports.ListItemDecorator>
                        {weight === '200' ? 'lighter' : weight}
                      </imports.MenuItem>
                    ))}
                  </imports.Menu>
                  <imports.IconButton
                    variant={italic ? 'soft' : 'plain'}
                    color={italic ? 'primary' : 'neutral'}
                    aria-pressed={italic}
                    onClick={() => setItalic((bool) => !bool)}
                  >
                    <imports.FormatItalic />
                  </imports.IconButton>
                  <imports.Button
                    sx={{
                      ml: 'auto',
                      backgroundColor: 'rgb(255, 135, 118)',
                      color: 'white',
                      '&:hover': {
                        color: 'white',
                        backgroundColor: 'rgb(255, 135, 118)',
                      },
                    }}
                    className="feedbacksend"
                    disabled={comment.length < 5 || comment.length > 30}
                    onClick={handleSend}
                  >
                    Send
                  </imports.Button>
                </imports.Box>
              }
              sx={{
                minWidth: 300,
                fontWeight,
                fontStyle: italic ? 'italic' : 'initial',
              }}
            />
          </imports.FormControl>
        </imports.Box>
      </imports.Modal>

      {certificate && <Popup handleClose={handleClose}/>}
    </div>
  );
}
