import React, { useState, useEffect } from "react";
import ModulesMain from "../ModulesMain";
import CustomTable from "../../../components/table/CustomTable";
import PrePostTest from "../pre-post-test/PrePostTest";
import Feedback from "../feedback/Feedback";
import "./Module6.css";
import { Input } from "@mui/material";
import { areAllFieldsFilled } from "../../../utils/Tools";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";
import { apiPostRequest } from "../../../utils/Network";
import calculateUsedTime from "../../../utils/Tools";

export default function Module6() {
    const navigate = useNavigate();
    toastr.options = {
        closeButton: true,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
        timeOut: 3000,
    };

    const [selectedComAcaStress, setSelectedComAcaStress] = useState([]);
    const [feedback, setFeedback] = useState(
        {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: ""
        }
    )
    const [preTestResults, setPreTestResults] = useState(
        {
            q1: "",
            q2: "",
            q3: "",
            q4: ""
        }
    )
    const [moduleDataActivity, setModuleDataActivity] = useState([]);
    const [postTestResults, setPostTestResults] = useState(
        {
            q1: "",
            q2: "",
            q3: "",
            q4: ""
        }
    )
    const [isPreTestCompleted, setIsPreTestCompleted] = useState(false);
    const [isActivityCompleted, setActivityCompleted] = useState(false);
    const [depressionActivity, setSelectedDepActivity] = useState(
        {
            "Being anrgy helps you solve your problem?": '',
            "It is ok to be angry every time?": '',
            "Being angry is the only way to show your hurt by someone?": '',
            "Anger is good for health?": ''
        }
    )

    const apiCall = async () => {
        try {
            const api = `${process.env.REACT_APP_BASE_URL}/eachmodulestatus`;
            const req_json = { "modulename": "module6", "modulenumber": 6 }
            const response = await apiPostRequest(api, req_json);
            if (response.data.success == true) {
                const dataToPass = response.data.message["modulestatus"];
                if (dataToPass.pretest === 'completed') {
                    setIsPreTestCompleted(true);
                }
                if (dataToPass.pretest === 'completed' && dataToPass.postTest === 'in-progress') {
                    setActivityCompleted(true);
                }
                if (dataToPass.pretest === 'completed' && dataToPass.activity === 'completed') {

                    setIsPreTestCompleted(true);
                    setActivityCompleted(true);
                }
                if (dataToPass.pretest === "completed" && dataToPass.activity === "completed" && dataToPass.posttest === "completed") {
                    navigate("/user-home/activity");
                }
            } else {
                toastr.error(response.msg);
            }
        }
        catch {
            toastr.error("There is an internal error! Please logout and login.")
        }
    }

    useEffect(() => {
        const modulestatus = JSON.parse(localStorage.getItem("all_module_status"))
        if (modulestatus[4].status === 'completed') {
            apiCall();
        } else {
            navigate('/user-home/activity')
        }
    }, []);



    const handleComAcaStressChange = (optionLabel) => {
        setSelectedComAcaStress((prevAnswers) => {
            const optionIndex = prevAnswers.indexOf(optionLabel);
            if (optionIndex !== -1) {
                const updatedAnswers = [...prevAnswers];
                updatedAnswers.splice(optionIndex, 1);
                return updatedAnswers;
            } else {
                return [...prevAnswers, optionLabel];
            }
        });
        setModule6([
            {
                "Which of the following is NOT an effect of anger on the body?": preTestResults.q1,
                "True or False: Anger feels the same for everyone.": preTestResults.q2,
                "Activity Question: When reflecting on the last time you were angry, what are some possible signs or signals of anger? Select all that apply.": preTestResults.q3,
                "True or False: Developing 'I-Messages' can help in expressing anger effectively.": preTestResults.q4,
                "What are some effects of anger on the mind? Select all that apply": selectedComAcaStress
            },

            {
                messages,
                depressionActivity,
                "How easy was it to go through course pages?": feedback.q1,
                "Was the module organized and structured properly?": feedback.q2,
                "Did the module increased your knowledge and skills in the subject matter?": feedback.q3,
                "How helpful did you find the module?": feedback.q4,
                "Rate your overall experience of the Module": feedback.q5,
            },
            {
                "Which of the following is NOT an effect of anger on the body?": postTestResults.q1,
                "True or False: Anger feels the same for everyone.": postTestResults.q2,
                "Activity Question: When reflecting on the last time you were angry, what are some possible signs or signals of anger? Select all that apply.": postTestResults.q3,
                "True or False: Developing 'I-Messages' can help in expressing anger effectively.": postTestResults.q4,
                "What are some effects of anger on the mind? Select all that apply": selectedComAcaStress,

            }

        ]);
    };

    const handleOptionChange = (question, option) => {
        setSelectedDepActivity(prevState => ({
            ...prevState,
            [question]: option
        }));
    };

    const handleFbRadioChange = (event, questionNumber) => {
        setFeedback((prevState) => ({
            ...prevState,
            [questionNumber]: event.target.value
        }));
    };
    const handlePreTestResults = (event, questionNumber) => {
        setPreTestResults((prevState) => ({
            ...prevState,
            [questionNumber]: event.target.value
        }));
    }

    const handlePostTestResults = (event, questionNumber) => {
        setPostTestResults((prevState) => ({
            ...prevState,
            [questionNumber]: event.target.value
        }));
        setModule6([
            {
                "Which of the following is NOT an effect of anger on the body?": preTestResults.q1,
                "True or False: Anger feels the same for everyone.": preTestResults.q2,
                "Activity Question: When reflecting on the last time you were angry, what are some possible signs or signals of anger? Select all that apply.": preTestResults.q3,
                "True or False: Developing 'I-Messages' can help in expressing anger effectively.": preTestResults.q4,
                "What are some effects of anger on the mind? Select all that apply": selectedComAcaStress
            },

            {
                messages,
                depressionActivity,
                "How easy was it to go through course pages?": feedback.q1,
                "Was the module organized and structured properly?": feedback.q2,
                "Did the module increased your knowledge and skills in the subject matter?": feedback.q3,
                "How helpful did you find the module?": feedback.q4,
                "Rate your overall experience of the Module": feedback.q5,
            },
            {
                "Which of the following is NOT an effect of anger on the body?": postTestResults.q1,
                "True or False: Anger feels the same for everyone.": postTestResults.q2,
                "Activity Question: When reflecting on the last time you were angry, what are some possible signs or signals of anger? Select all that apply.": postTestResults.q3,
                "True or False: Developing 'I-Messages' can help in expressing anger effectively.": postTestResults.q4,
                "What are some effects of anger on the mind? Select all that apply": selectedComAcaStress,

            }

        ]);
    }
    const [messages, setMessages] = useState([
        {
            'I feel': '',
            'when you': '',
            'because': '',
        },
        {
            'I feel': '',
            'when you': '',
            'because': '',
        },
        {
            'I feel': '',
            'when you': '',
            'because': '',
        },
        {
            'I feel': '',
            'when you': '',
            'because': '',
        },
        {
            'I feel': '',
            'when you': '',
            'because': '',
        },
    ]);

    const [module6, setModule6] = useState([]);




    const handleInputChange = (listIndex, key, value) => {
        const updatedMessages = [...messages];

        updatedMessages[listIndex][key] = value;
        const messagesWorksheet = {
            ...moduleDataActivity,
            "I feel": updatedMessages[0]["I feel"],
            "When you": updatedMessages[0]["When you"],
            "because": updatedMessages[0]["because"],
            "I feel": updatedMessages[1]["I feel"],
            "When you": updatedMessages[1]["When you"],
            "because": updatedMessages[1]["because"],
            "I feel": updatedMessages[2]["I feel"],
            "When you": updatedMessages[2]["When you"],
            "because": updatedMessages[2]["because"],
            "I feel": updatedMessages[3]["I feel"],
            "When you": updatedMessages[3]["When you"],
            "because": updatedMessages[3]["because"],
            "I feel": updatedMessages[4]["I feel"],
            "When you": updatedMessages[4]["When you"],
            "because": updatedMessages[4]["because"],

        }
        setModuleDataActivity(updatedMessages);

        setMessages(updatedMessages);
    };

    const testDetails = {
        q1: {
            ques: "Which of the following is NOT an effect of anger on the body?",
            data: [
                {
                    value: "Tightness in chest",
                    label: "Tightness in chest"
                },
                {
                    value: "Feeling hot",
                    label: "Feeling hot"
                },
                {
                    value: "Feeling guilty",
                    label: "Feeling guilty"
                },
                {
                    value: "Shaking or trembling",
                    label: "Shaking or trembling"
                }
            ]
        },
        q2: {
            ques: "True or False: Anger feels the same for everyone.",
            data: [
                {
                    value: "True",
                    label: "True"
                },
                {
                    value: "False",
                    label: "False"
                }
            ]
        },
        q3: {
            ques: "Activity Question: When reflecting on the last time you were angry, what are some possible signs or signals of anger? Select all that apply.",
            data: [
                {
                    value: "Raising one's voice",
                    label: "Raising one's voice"
                },
                {
                    value: "Feeling calm and relaxed",
                    label: "Feeling calm and relaxed"
                },
                {
                    value: "Flushed red face",
                    label: "Flushed red face"
                },
                {
                    value: "Clear thinking and focus",
                    label: "Clear thinking and focus"
                }
            ]
        },
        q4: {
            ques: "True or False: Developing 'I-Messages' can help in expressing anger effectively.",
            data: [
                {
                    value: "True",
                    label: "True"
                },
                {
                    value: "False",
                    label: "False"
                }
            ]
        }
    }


    const onModule6Submit = async () => {
        setSelectedComAcaStress([]);
        setActivityCompleted(true);
        try {
            let api = `${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

            let moduleData = {};

            for (let i = 0; i < 5; i++) {
                if (moduleDataActivity[i]) {
                    moduleData[`${i + 1}. I feel`] = moduleDataActivity[i]["I feel"] || '';
                    moduleData[`${i + 1}. when you`] = moduleDataActivity[i]["when you"] || '';
                    moduleData[`${i + 1}. because`] = moduleDataActivity[i]["because"] || '';
                } else {
                    moduleData[`${i + 1}. I feel`] = '';
                    moduleData[`${i + 1}. when you`] = '';
                    moduleData[`${i + 1}. because`] = '';
                }
            }

            moduleData["Being angry helps you solve your problem?"] = depressionActivity["Being angry helps you solve your problem?"] || '';
            moduleData["It is ok to be angry every time?"] = depressionActivity["It is ok to be angry every time?"] || '';
            moduleData["Being angry is the only way to show your hurt by someone?"] = depressionActivity["Being angry is the only way to show your hurt by someone?"] || '';
            moduleData["Anger is good for health?"] = depressionActivity["Anger is good for health?"] || '';

            moduleData["How easy was it to go through course pages?"] = feedback.q1 || '';
            moduleData["Was the module organized and structured properly?"] = feedback.q2 || '';
            moduleData["Did the module increase your knowledge and skills in the subject matter?"] = feedback.q3 || '';
            moduleData["How helpful did you find the module?"] = feedback.q4 || '';
            moduleData["Rate your overall experience of the Module"] = feedback.q5 || '';

            let req_json = {
                "modulename": "module6",
                "testtype": "activity",
                "moduleData": moduleData,
            };

            const response = await apiPostRequest(api, req_json);
        }
        catch {
            toastr.error("There is an internal error! Please logout and login.")
        }
    };


    const onModule6PreTestSubmit = async () => {

        setIsPreTestCompleted(true);
        const currentTime = localStorage.getItem("currentTime");
        try {
            let api =`${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

            let req_json = {
                "modulename": "module6",
                "testtype": "pretest",
                "moduleDataPretest": {
                    "Which of the following is NOT an effect of anger on the body?": preTestResults.q1,
                    "True or False: Anger feels the same for everyone.": preTestResults.q2,
                    "Activity Question: When reflecting on the last time you were angry, what are some possible signs or signals of anger? Select all that apply.": preTestResults.q3,
                    "True or False: Developing 'I-Messages' can help in expressing anger effectively.": preTestResults.q4,
                    "What are some effects of anger on the mind? Select all that apply": selectedComAcaStress
                },
                "currentTime": currentTime
            }
            const response = await apiPostRequest(api, req_json);
        }
        catch {
            toastr.error("There is an internal error! Please logout and login.")
        }

    }

    const onModule6PostTestSubmit = async () => {
        calculateUsedTime();
        const endTime = localStorage.getItem("currentTime");
        try {
            let api = `${process.env.REACT_APP_BASE_URL}/ModuleFunction`;

            let req_json = {
                "modulename": "module6",
                "testtype": "posttest",
                "moduleData": {
                    "Which of the following is NOT an effect of anger on the body?": postTestResults.q1,
                    "True or False: Anger feels the same for everyone.": postTestResults.q2,
                    "Activity Question: When reflecting on the last time you were angry, what are some possible signs or signals of anger? Select all that apply.": postTestResults.q3,
                    "True or False: Developing 'I-Messages' can help in expressing anger effectively.": postTestResults.q4,
                    "What are some effects of anger on the mind? Select all that apply": selectedComAcaStress,

                },
                "endTime": endTime
            }
            const response = await apiPostRequest(api, req_json);
            if (response.data.success === true) {
                navigate('/user-home/activity/Module7');
            }
        }
        catch {
            toastr.error("There is an internal error! Please logout and login.")
        }
    }



    return (
        <>
            <ModulesMain notDefault={true} />
            {
                !isPreTestCompleted && (
                    <PrePostTest
                        testType={'PRE-TEST'}
                        testDetails={testDetails}
                        selectedValue={preTestResults}
                        onOptSelect={handlePreTestResults}
                        onTestSubmit={onModule6PreTestSubmit}
                        moduleNo={6}
                        checkboxFun={handleComAcaStressChange}
                        selectedAnswers={selectedComAcaStress}
                        btnClass={`col-md-1 col-sm-3 p-2 fw-bold ${areAllFieldsFilled(preTestResults) == 'active-btn' && selectedComAcaStress.length ? 'active-btn' : 'inactive-btn'}`}
                    />
                )
            }

            {
                isPreTestCompleted && !isActivityCompleted && (
                    <div className="text-start module6-main-secton py-4 px-5">
                        <h1 className="mb-3 fw-bold">MODULE 6: Anger Management</h1>
                        <h4 className="fw-bold"> What does anger feel like?</h4>
                        <p>Anger feels different for everyone. You might experience some of the things listed below, and you might also have other experiences or difficulties that aren't listed here. </p>

                        <h4 className="fw-bold"> Effects on your body</h4>
                        <ol>
                            <li>Uncomfortable feel in stomach</li>
                            <li>ightness in chest</li>
                            <li>An increased and rapid heartbeat</li>
                            <li>Weakness in legs</li>
                            <li>Tight feel in muscles</li>
                            <li>Feeling  hot</li>
                            <li>Have an urge to go to the toilet</li>
                            <li>Sweating, especially your palms</li>
                            <li>Headache</li>
                            <li>Shaking or trembling</li>
                            <li>Dizziness</li>

                        </ol>
                        <h4 className="fw-bold"> Effects on your mind</h4>
                        <ol>
                            <li>Feeling tense, nervous or unable to relax</li>
                            <li>Feeling guilty</li>
                            <li>Feeling resentful towards other people or situations</li>
                            <li>Easily irritated</li>
                            <li>'Extreme anger</li>

                        </ol>
                        <h4 className="fw-bold">Activity-1: Understanding Anger</h4>
                        <p>Getting Started:</p>
                        <ul>
                            <li>Think about why and when anger occurs and what are signs [Record the anger signals. Responses may include: raising one's voice, hand shaking, jaw tightening, shorter breaths, tense muscles, flushed red face, etc.</li>
                            <li>reflect on the last time when they were angry. </li>
                            <li>recall where that anger came from, whether the angry feelings have synonyms, </li>
                            <li>such as frustration, rage, disappointment.</li>
                            <li>share, as best you can, what happened to them when they got  angry. (e.g.: went to sleep, yelled at their pet, confronted someone, cried, punched a wall, stopped talking, etc.).</li>
                        </ul>
                        <p>‘I-Messages’ Worksheet</p>
                        <p>Develop five I-Messages: </p>

                        <ol>
                            {messages.map((message, listIndex) => (
                                <li key={listIndex}>
                                    I feel <Input
                                        className="col-md-11 px-2 mx-1"
                                        value={message['I feel']}
                                        onChange={(e) => handleInputChange(listIndex, 'I feel', e.target.value)}
                                    /><br></br>
                                    {listIndex === 0 && '(Be specific)'}{listIndex === 0 && <br></br>}
                                    when you <Input
                                        className="col-md-11 px-2 mx-1"
                                        value={message['when you']}
                                        onChange={(e) => handleInputChange(listIndex, 'when you', e.target.value)}
                                    /><br></br>
                                    {listIndex === 0 && '(Give details of the behavior or circumstances)'}{listIndex === 0 && <br></br>}
                                    because <Input
                                        className="col-md-11 px-2 mx-1"
                                        value={message['because']}
                                        onChange={(e) => handleInputChange(listIndex, 'because', e.target.value)}
                                    /><br />
                                    {listIndex === 0 && '(This is the hard one: the "why")'}
                                </li>
                            ))}
                        </ol>
                        <h5>Discusssion:</h5>
                        <ol type="a">
                            <li>What was your reason to pick your response?</li>
                            <li>How does behaving like a lake feel?</li>
                            <li>What do you think about your choice?</li>
                            <li>Is it really the best choice?</li>
                        </ol>
                        <h4 className="fw-bold">
                            Some simple steps you can try to manage your anger are:
                        </h4>
                        <ul>
                            <li>Breathe deeply. Picture your breath coming up from your "gut."</li>
                            <li>Slowly repeat a calm word or phrases such as "relax," "take it easy.” Repeat it to yourself while breathing deeply.</li>
                            <li>Visualize a relaxing experience, from either your memory or your imagination.</li>
                            <li>Yoga-like activities can relax your muscles and make you feel much calmer.</li>

                        </ul>
                        <h4 className="fw-bold">Share your experience :</h4>
                        <CustomTable

                            columns={[
                                { id: 'q', label: 'Questions' },
                                { id: 'yes', label: 'Yes' },
                                { id: 'yes', label: 'No' },
                            ]}
                            rows={[
                                {
                                    q: 'Being anrgy helps you solve your problem?',
                                    yes: <input
                                        type="radio"
                                        value="yes"
                                        checked={depressionActivity['Being anrgy helps you solve your problem?'] == "yes"}
                                        onChange={(e) => handleOptionChange('Being anrgy helps you solve your problem?', 'yes')}
                                    />,
                                    no: <input
                                        type="radio"
                                        value="no"
                                        checked={depressionActivity['Being anrgy helps you solve your problem?'] == "no"}
                                        onChange={(e) => handleOptionChange('Being anrgy helps you solve your problem?', 'no')}
                                    />
                                },
                                {
                                    q: 'It is ok to be angry every time? ',
                                    yes: <input
                                        type="radio"
                                        value="yes"
                                        checked={depressionActivity['It is ok to be angry every time?'] == "yes"}
                                        onChange={(e) => handleOptionChange('It is ok to be angry every time?', 'yes')}
                                    />,
                                    no: <input
                                        type="radio"
                                        value="no"
                                        checked={depressionActivity['It is ok to be angry every time?'] == "no"}
                                        onChange={(e) => handleOptionChange('It is ok to be angry every time?', 'no')}
                                    />
                                },
                                {
                                    q: "Being angry is the only way to show your hurt by someone? ",
                                    yes: <input
                                        type="radio"
                                        value="yes"
                                        checked={depressionActivity['Being angry is the only way to show your hurt by someone?'] == "yes"}
                                        onChange={(e) => handleOptionChange('Being angry is the only way to show your hurt by someone?', 'yes')}
                                    />,
                                    no: <input
                                        type="radio"
                                        value="no"
                                        checked={depressionActivity['Being angry is the only way to show your hurt by someone?'] == "no"}
                                        onChange={(e) => handleOptionChange('Being angry is the only way to show your hurt by someone?', 'no')}
                                    />
                                },
                                {
                                    q: "Anger is good for health? ",
                                    yes: <input
                                        type="radio"
                                        value="yes"
                                        checked={depressionActivity['Anger is good for health?'] == "yes"}
                                        onChange={(e) => handleOptionChange('Anger is good for health?', 'yes')}
                                    />,
                                    no: <input
                                        type="radio"
                                        value="no"
                                        checked={depressionActivity['Anger is good for health?'] == "no"}
                                        onChange={(e) => handleOptionChange('Anger is good for health?', 'no')}
                                    />
                                }
                            ]}
                            className={"experience-table mt-3 mb-3"}
                        />
                        <h4 className="fw-bold">
                            Think on : Watch a video on :  Anger Management  by KLE Tech team, Hubbali
                        </h4>

                        <h4 className="fw-bold">
                            Feedback
                        </h4>

                        <Feedback
                            selectedValue={feedback}
                            onOptSelect={handleFbRadioChange}
                            onSubmit={onModule6Submit}
                        />
                    </div>
                )
            }
            {
                isPreTestCompleted && isActivityCompleted && (
                    <PrePostTest
                        testType={'POST-TEST'}
                        testDetails={testDetails}
                        selectedValue={postTestResults}
                        onOptSelect={handlePostTestResults}
                        onTestSubmit={onModule6PostTestSubmit}
                        moduleNo={6}
                        checkboxFun={handleComAcaStressChange}
                        selectedAnswers={selectedComAcaStress}
                        btnClass={`col-md-1 col-sm-3 p-2 fw-bold ${areAllFieldsFilled(postTestResults) == 'active-btn' && selectedComAcaStress.length ? 'active-btn' : 'inactive-btn'}`}
                    />
                )
            }
        </>
    )
}