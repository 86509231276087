import React from "react";
import "./Contact.css";
import aboutImg from "../../images/aboutImg.jpg";

export default function Contact() {
  return (
    <>
      <div className="contact-main-div col-md-12 row bg-dark justify-content-center m-0">
        <div className="text-light col-md-12 mb-2" style={{textAlign : "left"}}>
          <h3 className="text-decoration-underline">Contact</h3>
          <div className="mt-3 d-block col-md-12">
            <p style={{textAlign : "left"}}><i class="fa fa-user me-3"></i>Shivani Haritay Ph.D. Research Scholar JNMC, KAHER</p>
            <p className="d-flex">
              <a
                style={{ color: "white" }}
                className="me-3"
                target="_blank"
                href="https://maps.app.goo.gl/F81kBdTq84wwXfHF7"
              >
                <i class="fa-solid fa-location-dot"></i>
              </a>
              Jawaharlal Nehru Medical College, JNMC KLE University Campus,
              Nehru Nagar, Belagavi, Karnataka 590010
            </p>
            <p className="d-flex">
              <i class="fa-solid fa-phone me-3"></i>8618385095
            </p>
            <p className="d-flex">
              <i class="fa-solid fa-envelope me-3"></i> shivaniharitay@gmail.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
