import React from "react";
import "./Popup.css";
import * as imports from "../../Imports";
import Certificate from "../../pages/certificate/Certificate";
import Feedback from "../../components/feedback/Feedback"


export default function Popup({handleClose}){

        
    return(
       <div className="popup">
        
       <imports.Card className="popup_inner d-flex" style={{justifyContent:"space-around"}}>
       <Certificate handleClose={handleClose}/>
       </imports.Card>
       </div>
    )
}