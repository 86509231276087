import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout/Layout";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import Courses from "./pages/courses/Courses";
import Modules from "./pages/module-main/ModulesMain";
import Module1 from "./pages/module-main/modules/Module1";
import Module2 from "./pages/module-main/modules/Module2";
import Module3 from "./pages/module-main/modules/Module3";
import Module4 from "./pages/module-main/modules/Module4";
import Module5 from "./pages/module-main/modules/Module5";
import Module6 from "./pages/module-main/modules/Module6";
import Module7 from "./pages/module-main/modules/Module7";
import Module8 from "./pages/module-main/modules/Module8";
import Module9 from "./pages/module-main/modules/Module9";
import "./App.css";
import UserHome from "./pages/user-home/UserHome";
import Videos from "./pages/about/Videos";
import AdditionalResources from "./pages/additional-resources/AdditionalResources";
import NotFound from "./pages/not-found/NotFound";
import ActivityResults from "./pages/activity-results/ActivityResults";
import UserLogin from "./pages/login/user-login/UserLogin";
import AdminLogin from "./pages/login/admin-login/AdminLogin";
import AdminMainPage from "./pages/admin-portal/admin-main-page/AdminMainPage";
import LoginTabs from "./pages/login/LoginTabs";
import SuperAdminMainPage from "./pages/admin-portal/SuperAdmin-main-page/SuperAdminMainPage";
import SuperAdminLogin from "./pages/login/super-admin/SuperAdmin";

function App() {
  const pages = [
    { path: "/user-home", component: <UserHome /> },
    { path: "/user-home/activity", component: <Modules /> },
    { path: "/Home", component: <Home /> },
    { path: "/About", component: <About /> },
    { path: "/About/Videos", component: <Videos /> },
    { path: "/Courses", component: <Courses /> },
    { path: "/Contact", component: <Contact /> },
    { path: "/Login", component: <LoginTabs /> },
    { path: "/Login/UserLogin", component: <UserLogin /> },
    { path: "/Login/AdminLogin", component: <AdminLogin /> },
    { path: "/Login/SuperAdminLogin", component: <SuperAdminLogin /> },
    { path: "/SuperAdmin", component: <SuperAdminMainPage /> },
    { path: "/Signup", component: <Signup /> },
    { path: "/", component: <Home />},
    { path: "/user-home/home", component: <Layout />},
    { path: "/user-home/activity/Module1", component: <Module1 /> },
    { path: "/user-home/activity/Module2", component: <Module2 /> },
    { path: "/user-home/activity/Module3", component: <Module3 /> },
    { path: "/user-home/activity/Module4", component: <Module4 /> },
    { path: "/user-home/activity/Module5", component: <Module5 /> },
    { path: "/user-home/activity/Module6", component: <Module6 /> },
    { path: "/user-home/activity/Module7", component: <Module7 /> },
    { path: "/user-home/activity/Module8", component: <Module8 /> },
    { path: "/user-home/activity/Module9", component: <Module9 /> },
    { path: "/user-home/additional-resources", component: <AdditionalResources/> },
    { path: "/activity-results", component: <ActivityResults/> },
    { path: "/admin", component: <AdminMainPage/> }
  ];
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {pages.map((page, index) => (
            <Route path={page.path} key={index} element={page.component} />
          ))}
          
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
