import React, { useEffect, useState } from "react";
import DynamicLayout from "../../dynamic-layout/DynamicLayout";
import "../Login.css";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Loader from "../../../components/loader/Loader";
import { apiPostRequest } from "../../../utils/Network";

export default function AdminLogin() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [adminInputs, setAdminInputs] = useState({
    email: "",
    password: "",
  });

  const handleInputs = (e) => {
    const { id, value } = e.target;
    setAdminInputs((prevInputs) => ({
      ...prevInputs,
      [id]: value,
    }));
  };

  // const onLogin = () => {
  //   setLoader(true);
  //   if (
  //     adminInputs.email === "shivaniharitay@gmail.com" &&
  //     adminInputs.password === "root@123"
  //   ) {
  //     localStorage.setItem("isAdminLoggedIn", true);
  //     setLoader(false);
  //     navigate("/admin");
  //   } else {
  //     setLoader(false);
  //     toastr.error("Please enter valid admin credentials");
  //   }
  // };

  const onLogin = async () => {
    setLoader(true);
  
    const api = `${process.env.REACT_APP_BASE_URL}/AdminLogin`;
    let req_json = {
      email: adminInputs.email,
      password: adminInputs.password,
    }
    try {
      const response = await apiPostRequest(api, req_json);
  
      if (response.data.success) {
        console.log("login")
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('isAdminLoggedIn',true)
        setLoader(false);
        navigate('/admin');
      } else {
        setLoader(false);
        toastr.error(response.data.message);
      }
    } catch (error) {
      setLoader(false);
      if (error.response && error.response.data) {
        toastr.error(error.response.data.message);
      } else {
        toastr.error('An error occurred during login. Please try again later.');
      }
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("isAdminLoggedIn")) === true) {
      navigate("/admin");
    }
  }, [navigate]);

  return (
    <>
      <div style={{ float: "right", fontSize: "large" }} className="m-1">
        <button
          style={{
            border: "none",
            backgroundColor: "white",
            color: "grey",
            textDecoration: "underline",
          }}
          onClick={() => navigate("/signup")}
        >
          Signup
        </button>
      </div>
      <br />
      <div className="mt-5">
        <DynamicLayout header={"Admin Login"} >
          {loader && <Loader />}
          <div className="col-md-12 p-4 loginforTab">
            <input
              className="textfield"
              onChange={handleInputs}
              type="email"
              id="email"
              value={adminInputs.email}
              placeholder="Email"
              style={{
                marginTop: "10%",
                width: "50vh",
                borderRadius: "22px",
                padding: "8px",
                border: "1px solid grey",
              }}
            /><br />
            <input
              className="textfield"
              onChange={handleInputs}
              type="password"
              id="password"
              value={adminInputs.password}
              placeholder="Password"
              style={{
                marginTop: "2%",
                width: "50vh",
                borderRadius: "22px",
                padding: "8px",
                border: "1px solid grey",
              }}
            />
            <br />
            <button
              className="loginBtn"
              disabled={adminInputs.email && adminInputs.password ? false : true}
              onClick={onLogin}
            >
              Login
            </button>
          </div>
        </DynamicLayout>
      </div>
    </>
  );
}
