import React from "react";
import "./Video.css";
import video1 from "../../../videos/anger-management.mp4";
import video2 from "../../../videos/anxiety-management.mp4";

export default function Video() {
  const videos = [video1, video2];

  return (
    <>
      <div className="row mt-4 video-main-section text-start">
        {videos.map((eachVideo, videoId) => (
          <div className="col-md-4" key={videoId} height={250} offset={100}>
            <video
              key={videoId}
              className="video-section col-md-12 p-2 me-2"
              src={eachVideo}
              title="YouTube video player"
              controls
              autoPlay={false}
              preload="none"
            ></video>
          </div>
        ))}
      </div>
    </>
  );
}
